import isEqual from "lodash/isEqual"
import { GetFieldType, KeysOfUnion, RecursiveKeyOf } from "../@appnflat-types/helpers"
import removeUndefined from "./removeUndefined"

/**
 * Sets the value at the path of the object.
 *
 * This function mutates the given object.
 */
export function set<T extends object, P extends RecursiveKeyOf<T>>(
    obj: T,
    path: P,
    value: GetFieldType<T, P>
) {
    const keyArray = String(path)
        .split(".")
        .map((p) => (/^\[\d+\]$/.test(p) ? Number(p.replace(/^\[|\]$/g, "")) : p))
    keyArray.reduce((acc: any, key, i, array) => {
        const k = key as keyof typeof acc
        if (acc[k] === undefined || typeof acc[k] !== "object") {
            if (typeof array[i + 1] === "number") acc[k] = []
            else acc[k] = {}
        }
        if (i === keyArray.length - 1) acc[k] = value
        return acc[k]
    }, obj)
}

/**
 * Deletes the value at the path of the object.
 *
 * This function mutates the given object.
 */
export function unset<T extends object, P extends RecursiveKeyOf<T>>(obj: T, path: P) {
    const keyArray = String(path)
        .split(".")
        .map((p) => (/^\[\d+\]$/.test(p) ? Number(p.replace(/^\[|\]$/g, "")) : p))
    keyArray.reduce((acc: any, key, i, array) => {
        const k = key as keyof typeof acc
        if (acc[k] === undefined) {
            if (typeof array[i + 1] === "number") acc[k] = []
            else acc[k] = {}
        }
        if (i === keyArray.length - 1) delete acc[k]
        return acc[k]
    }, obj)
}

/**
 * Gets the value at the path of the object.
 */
export function get<
    Obj extends object,
    Path extends RecursiveKeyOf<Obj>,
    Result = GetFieldType<Obj, Path>,
>(obj: Obj, path: Path): Result {
    return String(path)
        .replace(/\[|\]/g, "")
        .split(".")
        .reduce<Result>((acc, part) => (acc as any)?.[part], obj as any)
}

function isOmittable(v: any) {
    return v === undefined || v === null || v === "" || Number.isNaN(v)
}

/**
 * Cleans an object deeply by removing certain values.
 *
 * @param obj The object to clean.
 * @param omit A function that returns true if the value should be omitted.
 * By default, the function omits undefined, null, empty strings, and NaN values.
 *
 * @example
 * omitByDeep({ a: undefined, b: null, c: "", d: NaN, e: 0 }) // { e: 0 }
 * omitByDeep({ a: { b: undefined, c: null, d: "", e: NaN, f: 0 } }) // { a: { f: 0 } }
 * omitByDeep({ hello: { name: "", age: -1 }, value: { a: null, b: [undefined, 8, 0] } }) // { hello: { age: -1 }, value: { b: [8, 0] } }
 * omitByDeep("") // ""
 * omitByDeep(0) // 0
 */
export function omitByDeep<T>(obj: T, omit = isOmittable): T {
    if (Array.isArray(obj)) {
        return obj
            .map((item) => (item instanceof Object ? omitByDeep(item, omit) : item))
            .filter((item) => !omit(item)) as unknown as T
    } else if (obj instanceof Object) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (!omit(value)) {
                // @ts-ignore
                acc[key] = value instanceof Object ? omitByDeep(value, omit) : value
            }
            return acc
        }, {} as T)
    } else {
        return obj
    }
}

/** Typed version of `Object.entries`. */
export function objectEntries<T extends object>(obj: T) {
    return Object.entries(obj) as [keyof T, T[keyof T]][]
}

// /** Typed version of `Object.fromEntries`. */
// export function objectFromEntries<K extends string, V>(entries: [K, V][]) {
//     return Object.fromEntries(entries) as Record<K, V>
// }

/** Verifies if two objects are equal, ignoring a set of fields.
 *
 * @returns `true` if the objects are equal, or the first field that is different.
 */
export function equalIgnoringFields<T extends object>(
    a: T,
    b: T,
    fieldsThatCanBeChanged: Set<KeysOfUnion<T> | undefined>
): true | string {
    const _a = removeUndefined(a, true)
    const _b = removeUndefined(b, true)
    const changes = [
        ...Object.entries(_a).map(([key, value]) =>
            !fieldsThatCanBeChanged.has(key as any) && !isEqual(_b[key as keyof typeof _b], value)
                ? key
                : false
        ),
        ...Object.entries(_b).map(([key, value]) =>
            !fieldsThatCanBeChanged.has(key as any) && !isEqual(_a[key as keyof typeof _a], value)
                ? key
                : false
        ),
    ]
    const firstChange = changes.find((change) => !!change)
    return firstChange || true
}
