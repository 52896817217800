import { z } from "zod"
import { Role } from "../Role"
import { uidString } from "./BaseStrings"

/** The user details within a building. */
export const buildingUserSchema = z.object({
    uid: uidString,
    role: z.nativeEnum(Role).optional(),
    banned: z.boolean().optional(),
})
export type BuildingUser = z.infer<typeof buildingUserSchema>
