import { toDinero, safeDineroFactory } from "./dineroExtensions"
import { DineroStorable } from "../@appnflat-types/schemas/Common"
import { Currency, Dinero } from "dinero.js"

/**
 * Given a dictionary of amounts by date, it sums all the amounts and returns the sum.
 */
export function sumAmountsByDate(
    amountsByDate: Record<string, DineroStorable | undefined> | undefined,
    currency: Currency | undefined
): Dinero {
    try {
        if (!amountsByDate) return toDinero(0, currency)
        return (
            Object.keys(amountsByDate)
                .flatMap((date) =>
                    Object.prototype.hasOwnProperty.call(amountsByDate, date)
                        ? [amountsByDate[date]]
                        : []
                )
                .reduce(
                    (acc, amount) => acc.add(safeDineroFactory(amount, currency)),
                    toDinero(0, currency)
                ) ?? toDinero(0, currency)
        )
    } catch {
        return toDinero(0, currency)
    }
}
