import React, { ReactNode, useState } from "react"
import { KeyboardKey, useAppTranslation, useKey } from "../../hooks/hooks"
import { Group, Modal, Stack } from "@mantine/core"
import { TKey } from "../../i18n"
// import "../../css/KeyboardShortcutsPopup.css"
import { IconCommand } from "@tabler/icons-react"

export default function KeyboardShortcutsPopup() {
    const [show, setShow] = useState(false)
    const t = useAppTranslation()
    useKey(undefined, "?", () => setShow(!show))

    function Item({
        icon,
        keyboardKey,
        explanation,
    }: {
        icon?: ReactNode
        keyboardKey: KeyboardKey | ""
        explanation: TKey
    }) {
        return (
            <Group>
                {icon ? (
                    <>
                        <kbd>{icon ? icon : undefined}</kbd>{" "}
                    </>
                ) : (
                    ""
                )}
                <kbd>{keyboardKey.toUpperCase()}</kbd>
                {t(explanation)}
            </Group>
        )
    }

    return (
        <Modal opened={show} onClose={() => setShow(false)} title={t("core:keyboard_shortcuts")}>
            <Stack>
                <Item keyboardKey="?" explanation="core:show_help" />
                <Item keyboardKey="/" explanation="core:search" />
                <Item icon={<IconCommand />} keyboardKey="s" explanation="core:save" />
                <Item icon={<IconCommand />} keyboardKey="e" explanation="core:edit" />
            </Stack>
        </Modal>
    )
}
