import React, { useContext, useEffect, useState } from "react"
import { Group, Modal } from "@mantine/core"
import { Button } from "@mantine/core"
import { DateTime } from "@shared/dates"
import {
    useAppTranslation,
    usePermissions,
    useAppSelector,
    useAppParams,
    useAppFormatter,
} from "hooks/hooks"
import { cachedBuildingSelector } from "store/cache"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { useForm } from "hooks/useForm"
import { WDateInput } from "components/Inputs/WDateInput"
import { setLoadingState } from "logic/notifications"
import { RemoteConfigContext } from "server/RemoteConfigProvider"
import { DateFormat } from "@shared/formatter"
import { POST, requestHandler } from "server/wrappedCallers"

/**
 * Checks if the last fiscal year was created over 23 months ago and if so, forces the user to create a new
 * fiscal year.
 */
export default function PromptForceNewFY() {
    const t = useAppTranslation()
    const building = useAppSelector(cachedBuildingSelector)
    const { buildingRef } = useAppParams()
    const navigate = useNavigate()
    const { MAX_WEEKS_IN_FY } = useContext(RemoteConfigContext)
    const { hasFinancialAccessWrite } = usePermissions()
    const [startDateLocale, setStartDateLocale] = useState("")
    const [showPopup, setShowPopup] = useState(false)
    const mostRecentFY = Math.max(...(building?.fiscalYears.length ? building.fiscalYears : [0]))

    const formNewFYSchema = z.object({
        newYear: z.number().min(mostRecentFY).max(new DateTime("now").toSeconds()),
    })

    function onSubmit(data: z.infer<typeof formNewFYSchema>) {
        if (!buildingRef) return
        setShowPopup(false)
        setLoadingState({
            tkey: "messages:creating_new_fiscal_year_please_wait_this_can_take_a_few_minutes",
            icon: "fiscal-year",
        })
        requestHandler({
            errorTitle: "error_creating_a_new_fiscal_year",
            request: POST("/buildings/new-fiscal-year", {
                params: { query: { buildingRef } },
                body: data,
            }),
            onFinally: () => setLoadingState(false),
            onResult: () => {
                navigate("/app")
            },
            onError: () => {
                setShowPopup(true)
            },
        })
    }

    const form = useForm(formNewFYSchema, onSubmit)
    const formatter = useAppFormatter()

    useEffect(() => {
        // If the user is not an admin or an accountant with write permission, do not show the prompt.
        if (!hasFinancialAccessWrite || !mostRecentFY) {
            setShowPopup(false)
        } else {
            const startDate = new DateTime(mostRecentFY)
            setStartDateLocale(formatter.dateToLocale(startDate, DateFormat.date))
            setShowPopup(new DateTime("now") >= startDate.plus({ weeks: MAX_WEEKS_IN_FY }))
        }
    }, [formatter, mostRecentFY, hasFinancialAccessWrite, MAX_WEEKS_IN_FY])

    return (
        <Modal
            opened={showPopup}
            onClose={() => void 0}
            title={t("messages:you_must_create_a_new_fiscal_year")}
            withCloseButton={false}
        >
            {t("messages:the_current_fiscal_year_started_on_the_which_is_more_than_23_months_ago", {
                startDate: startDateLocale,
            })}
            <WDateInput
                mt="20"
                editing
                label="core:start_date"
                {...form.propsForWDateInput("newYear")}
            />
            <Group justify="flex-end">
                <Button onClick={form.submit}>{t("core:start_new_fiscal_year")}</Button>
            </Group>
        </Modal>
    )
}
