import { supportedLanguages } from "hooks/hooks"
import { errorCodesMapping } from "./errorCodesMapping"
import { SimpleTKey, translate } from "./translate"

function isLocalizedMessage(value: any): value is { en: string; fr: string } {
    return (
        typeof value === "object" &&
        !!value &&
        typeof value.en === "string" &&
        typeof value.fr === "string"
    )
}

function hasMessage(error: unknown): error is {
    localizedMessage?: { en: string; fr: string }
    message?: string
    name?: string
    code?: string
} {
    const casted = error as {
        localizedMessage?: { en: string; fr: string } | string
        message?: string
        name?: string
        code?: string
    }
    return (
        typeof casted === "object" &&
        !!casted &&
        (!casted.localizedMessage ||
            isLocalizedMessage(casted.localizedMessage) ||
            typeof casted.localizedMessage === "string") &&
        (!casted.message || typeof casted.message === "string") &&
        (!casted.name || typeof casted.name === "string") &&
        (!casted.code || typeof casted.code === "string")
    )
}

function errorToCodes(error: any) {
    if (typeof error === "string") return [error]
    else if (hasMessage(error)) {
        const language = process.env.IS_TEST
            ? "en"
            : (supportedLanguages.find((l) => navigator.language.startsWith(l)) ?? "en")
        if (typeof error.localizedMessage === "string") {
            return [error.localizedMessage, error.message, error.name, error.code]
        } else {
            return [error.localizedMessage?.[language], error.message, error.name, error.code]
        }
    } else return []
}

/** Converts an error code to a message. */
export default function errorCodeToMessage(error: any, defaultKey?: SimpleTKey) {
    const errorCodes = errorToCodes(error)
    if (errorCodes[0]) return errorCodes[0]
    for (const code of errorCodes) {
        if (code) {
            const key = errorCodesMapping[code]
            if (key) {
                const details =
                    typeof error === "object" &&
                    "details" in error &&
                    typeof error.details === "string"
                        ? error.details
                        : translate("unknown_error_occurred")
                return translate(key, { details })
            }
        }
    }
    return translate(defaultKey ?? "unknown_error_occurred")
}
