import React, { ChangeEvent, useState, KeyboardEvent } from "react"
import { RecursiveKeyOf, RecursiveKeysOfType, DeepPartial } from "@appnflat-types/helpers"
import { z, ZodIssue, ZodTypeAny } from "zod"
import { DineroStorable } from "@appnflat-types/schemas/Common"
import { dineroStorableSchema } from "@appnflat-types/schemas/Common"
import cloneDeep from "lodash/cloneDeep"
import type { GetFieldType } from "lodash"
import { IconTrash } from "@tabler/icons-react"
import { WSelectOption } from "components/Inputs/WSelect"
import { useDeepCompareEffect } from "./useDeepCompareEffect"
import { useAppLanguage, useAppTranslation } from "./hooks"
import { showErrorNotification } from "logic/notifications"
import { ParsingErrors, parsingErrorLocale } from "@appnflat-types/schemas/parsingErrors"
import { DateTime } from "@shared/dates"
import { set, unset, get as sharedGet, omitByDeep } from "@shared/objects"
import { FileUpload, fileUploadSchema } from "@appnflat-types/schemas/FileUpload"

/** This hook returns a set of functions and objects to help manage forms. */
export function useForm<S extends ZodTypeAny, T = z.infer<S>>(
    schema: S,
    /** The function to call when submitting the form. */
    onSubmit: (value: T) => void,
    {
        initialValues,
        beforeOnSubmit,
        beforeSetInitialData,
        silentFailSubmit = false,
        clearOnSubmit = true,
    }: {
        /** The initial value of the form. */
        initialValues?: DeepPartial<T> | undefined
        /**
         * A function to apply before submitting the form. The result (if defined) will be
         * used as the current values of the merged data before checking the validity of the
         * merged data and calling `onSubmit.`
         */
        beforeOnSubmit?: (value: DeepPartial<T> | undefined) => DeepPartial<T> | undefined
        /**
         * A function to apply before setting the initial values.
         * @example - Multiply percentages by 100 to show `50%` instead of `0.5`.
         */
        beforeSetInitialData?: (value: DeepPartial<T> | undefined) => DeepPartial<T> | undefined
        /** If true, no notifications will be shown when the form cannot be submitted.
         * @default false
         */
        silentFailSubmit?: boolean
        /** If true, the form will be cleared when it is submitted.
         * @default true
         */
        clearOnSubmit?: boolean
    } = {}
) {
    const language = useAppLanguage()
    const t = useAppTranslation()
    /** The initial values of the form. */
    const [initialData, setInitialData] = useState<DeepPartial<T> | undefined>(undefined)
    /** The edited values of the form. */
    const [data, setData] = useState<DeepPartial<T> | undefined>(undefined)
    /** The list of issues in the form. */
    const [errors, setErrors] = useState<ZodIssue[]>([])

    function get(path: string | symbol | number): any {
        return sharedGet<any, any, any>(data ?? {}, path)
    }

    /** Submits the form when the enter key is pressed. */
    function submitOnEnter(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            e.preventDefault()
            submit()
        }
    }

    function propsForWValueDisplay(field: RecursiveKeysOfType<T, string>) {
        return {
            value: toString(get(field)),
        }
    }

    function propsForWDateInput(field: RecursiveKeysOfType<T, number>) {
        const value = get(field)
        const { min, max } = limitsOfField(schema, field)
        return {
            value,
            onChange: (newValue: number | undefined | null) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            maxDate: max ? new DateTime(max).toDate() : undefined,
            minDate: min ? new DateTime(min).toDate() : undefined,
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWTextInput(
        field: RecursiveKeysOfType<T, string>,
        options: {
            /** If true, the form will be submitted when the enter key is pressed. */
            submitOnEnter?: boolean
        } = { submitOnEnter: false }
    ) {
        const { min, max } = limitsOfField(schema, field)
        return {
            value: toString(get(field)),
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, event.currentTarget.value)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
            onKeyDown: options.submitOnEnter ? submitOnEnter : undefined,
            maxLength: max,
            minLength: min,
        }
    }

    function propsForWColorPicker(field: RecursiveKeysOfType<T, string>) {
        return {
            value: toString(get(field)),
            onChange: (value: string) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, value)
                setData(updatedData)
            },
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWNumberInput(field: RecursiveKeysOfType<T, number>) {
        const { min, max } = limitsOfField(schema, field)
        return {
            value: get(field),
            onChange: (newValue: number | string | undefined) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            min,
            max,
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWMarkdownEditor(field: RecursiveKeysOfType<T, string>) {
        const value = z.string().safeParse(get(field))
        return {
            value: value.success ? value.data : "",
            setValue: (newValue: string | undefined) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWDineroInput(field: RecursiveKeysOfType<T, DineroStorable>) {
        const value = dineroStorableSchema.safeParse(get(field))
        return {
            value: value.success ? value.data : undefined,
            setValue: (newValue: DineroStorable) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWSelect(field: RecursiveKeysOfType<T, string>) {
        return {
            value: get(field),
            onChange: (newValue: string | null) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWSelectMapped<E>(
        field: RecursiveKeysOfType<T, E>,
        /** The set of entries, where the `value` key is the value to be passed to the select object. */
        options: WSelectMappedOption<E>[],
        /**
         * Given an entry (what is to be saved), returns a value (the key of the entry
         * for the select.)
         */
        entryToValue: (options: WSelectMappedOption<E>[], entry: E) => string | null,
        /**
         * Given a value (the key of the entry for the select), returns an entry (what
         * is to be saved).
         */
        valueToEntry: (options: WSelectMappedOption<E>[], value: string | null) => E | undefined
    ) {
        return {
            value: entryToValue(options, get(field)),
            onChange: (newValue: string | null) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, valueToEntry(options, newValue))
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
            options,
        }
    }

    function propsForWMultiSelect(field: RecursiveKeysOfType<T, string[]>) {
        return {
            value: get(field),
            onChange: (newValue: string[] | null) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWEmailsInput(
        field: RecursiveKeysOfType<T, { address: string; name: string | undefined }[]>
    ) {
        return {
            value: get(field),
            onChange: (newValue: { address: string; name: string | undefined }[] | null) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, newValue)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWCheckbox(field: RecursiveKeysOfType<T, boolean>) {
        return {
            checked: Boolean(get(field)),
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                const updatedData = cloneDeep(data ?? {})
                set<any, any>(updatedData, field, event.currentTarget.checked)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWFileInput(field: RecursiveKeysOfType<T, FileUpload>) {
        const parseResult = fileUploadSchema.partial().safeParse(get(field))
        return {
            fileData: parseResult.success ? parseResult.data.dataURL : undefined,
            onChange: async (file: FileUpload | null) => {
                const updatedData = cloneDeep(data ?? {})
                if (file) {
                    set<any, any>(updatedData, field, file)
                } else {
                    set<any, any>(updatedData, field, "delete")
                }
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function propsForWMultipleFileInput(field: RecursiveKeysOfType<T, FileUpload[]>) {
        const parseResult = z.array(fileUploadSchema.partial()).safeParse(get(field))
        return {
            files: parseResult.success ? parseResult.data : undefined,
            onChange: async (file: FileUpload | null, index: number) => {
                const updatedData = cloneDeep(data ?? {})
                const currentFiles = sharedGet<any, any, FileUpload[] | undefined>(
                    updatedData,
                    field
                )
                const newFiles: (FileUpload | null)[] = []
                for (let i = 0, n = Math.max(currentFiles?.length ?? 0, index + 1); i < n; i++) {
                    if (i === index) {
                        if (file === null) continue
                        newFiles.push(file)
                    } else {
                        newFiles.push(currentFiles?.[i] ?? null)
                    }
                }
                set<any, any>(updatedData, field, newFiles)
                setData(updatedData)
            },
            required: isRequiredField(schema, field),
            error: findErrorMessage(errors, field, language),
        }
    }

    function addToList<
        F extends RecursiveKeysOfType<T, any[]> & string,
        E extends DeepPartial<GetFieldType<T, `${F}[0]`>>,
    >(field: F, newEntry: E, ensureUnique = false) {
        const updatedData = cloneDeep(data ?? {})
        const value: unknown = get(field)
        if (Array.isArray(value)) {
            set<any, any>(
                updatedData,
                field,
                [...value, newEntry].filter((v, i, a) => !ensureUnique || a.indexOf(v) === i)
            )
        } else {
            set<any, any>(updatedData, `${field}.[0]`, newEntry)
        }
        setData(updatedData)
    }

    function removeFromList<F extends RecursiveKeysOfType<T, any[]>>(
        field: F,
        filter: { index: number } | { value: any }
    ) {
        const updatedData = cloneDeep(data ?? {})
        set<any, any>(
            updatedData,
            field,
            [...(get(field) ?? [])].filter((v, i) =>
                "index" in filter ? i !== filter.index : v !== filter.value
            )
        )
        setData(updatedData)
    }

    function propsForButtonAddEntryToList<
        F extends RecursiveKeysOfType<T, any[]> & string,
        E extends DeepPartial<GetFieldType<T, `${F}[0]`>>,
    >(field: F, newEntry: E, ensureUnique = false) {
        return {
            onClick: () => addToList(field, newEntry, ensureUnique),
        }
    }

    function propsForButtonAddFromList<
        F extends RecursiveKeysOfType<T, any[]> & string,
        E extends DeepPartial<GetFieldType<T, `${F}[0]`>>,
    >(field: F, ensureUnique = false) {
        return {
            onAdd: (newEntry: E) => addToList(field, newEntry, ensureUnique),
        }
    }

    function propsForButtonRemoveEntryFromList(
        field: RecursiveKeysOfType<T, any[]>,
        filter: { index: number } | { value: any }
    ) {
        return {
            c: "red",
            color: "red",
            "aria-label": t("core:delete"),
            variant: "icon",
            rightSection: <IconTrash />,
            onClick: () => removeFromList(field, filter),
        }
    }

    function reset() {
        setErrors([])
        setData(initialData)
    }

    function setInitialValues(newInitialData: DeepPartial<T> | undefined) {
        const dataToSet = beforeSetInitialData
            ? beforeSetInitialData(newInitialData)
            : newInitialData
        setData(dataToSet)
        setInitialData(dataToSet)
    }

    useDeepCompareEffect(() => {
        setInitialValues(initialValues)
    }, [initialValues])

    function setEditedValues(edits: DeepPartial<T>) {
        setData({ ...(data ?? {}), ...edits })
    }

    function deleteField(field: RecursiveKeyOf<T>) {
        const updatedData = cloneDeep(data ?? {})
        unset<any, any>(updatedData, field)
        setData(updatedData)
    }

    function submit() {
        const beforeOnSubmitData = beforeOnSubmit?.(data) ?? data
        const cleanedData = omitByDeep(beforeOnSubmitData)
        const validatedData = schema.safeParse(cleanedData)
        if (!validatedData.success) {
            console.error("Submit form parsing error:", {
                errors: validatedData.error,
                cleanedData,
                beforeOnSubmitData,
            })
            const issues = validatedData.error.issues.map((issue) => ({
                ...issue,
                message: parsingErrorLocale(issue.message, language),
            }))
            setErrors(validatedData.error.issues)
            let message =
                issues.find((issue) => !!issue.message)?.message ?? t("core:invalid_value")
            // There is absolutely no data in the form.
            if (!issues[0]?.path.length) message = t("core:please_fill_the_form")
            // A required field is missing.
            else if (issues[0].code === "invalid_type" && issues[0].received === "undefined")
                message = t("core:required_field_missing")
            if (message && !silentFailSubmit) showErrorNotification({ customMessage: message })
        } else {
            console.log("Submit form result:", {
                data: validatedData.data,
                cleanedData,
                beforeOnSubmitData,
            })
            onSubmit(validatedData.data)
            if (clearOnSubmit) reset()
            else setErrors([])
        }
    }

    return {
        /** The current values of the form. This is a **READONLY** value. */
        data,

        /**
         * Sets the values of some fields to the given value. Does not change the values
         * of fields not specified.
         */
        setEditedValues,

        /** Removes a given field from the edited data. */
        deleteField,

        /**
         * Submits the form. Verifies the data is valid. If the data is not valid,
         * sets errors. Otherwise, calls the onSubmit function.
         */
        submit,

        /** Resets the form. */
        reset,

        /** Returns the props for a WValueDisplay. */
        propsForWValueDisplay,

        /** Returns the props for a WDateInput. */
        propsForWDateInput,

        /** Returns the props for a WTextInput. */
        propsForWTextInput,

        /** Returns the props for a WColorPicker. */
        propsForWColorPicker,

        /** Returns the props for a WNumberInput. */
        propsForWNumberInput,

        /** Returns the props for a WMarkdownEditor. */
        propsForWMarkdownEditor,

        /** Returns the props for a WDineroInput. */
        propsForWDineroInput,

        /** Returns the props for a WSelect. */
        propsForWSelect,

        /** Returns the props for a WSelect where the value we want to save is not a string. */
        propsForWSelectMapped,

        /** Returns the props for a WSelect. */
        propsForWMultiSelect,

        /** Returns the props for a WCheckbox. */
        propsForWCheckbox,

        /** Returns the props for a WFileInput. */
        propsForWFileInput,

        /** Returns the props for a WMultipleFileInput. */
        propsForWMultipleFileInput,

        /** Returns the props for a WEmailsInput. */
        propsForWEmailsInput,

        /**
         * Returns the props for a button that will add a new entry to a list.
         *
         * @param field - The field of the list.
         * @param newEntry - The new entry to add to the list.
         * @param ensureUnique - Whether to ensure the entry is unique in the list. False by default.
         */
        propsForButtonAddEntryToList,

        /**
         * Returns the props for ButtonAddFromList.
         *
         * @param field - The field of the list.
         * @param ensureUnique - Whether to ensure the entry is unique in the list. False by default.
         */
        propsForButtonAddFromList,

        /**
         * Returns the props for a button that will remove an entry at a given index from a list.
         *
         * @param field - The field of the list.
         * @param filter - The filter to find the item to remove. Either an index of the list or the value to remove.
         */
        propsForButtonRemoveEntryFromList,

        /**
         * Adds an entry to a list.
         *
         * @param field - The field of the list.
         * @param newEntry - The new entry to add to the list.
         * @param ensureUnique - Whether to ensure the entry is unique in the list. False by default.
         * @example - `addToList("list", "value")` adds the value "value" to the field "list".
         */
        addToList,

        /**
         * Removes an entry from a list.
         *
         * @param field - The field of the list.
         * @param filter - The filter to find the item to remove. Either an index of the list or the value to remove.
         * @example - `removeFromList("list", { index: 0 })` removes the first item of the field "list".
         * @example - `removeFromList("list", { value: "value" })` removes the item with the value "value" of the field "list".
         */
        removeFromList,
    }
}

export type WSelectMappedOption<E> = WSelectOption & { entry: E }

export type UseFormReturn<T extends ZodTypeAny> = ReturnType<typeof useForm<T>>
export type UseFormPropsGenerators<T extends ZodTypeAny> = Omit<
    ReturnType<typeof useForm<T>>,
    "data" | "submit" | "setInitialValues"
>

/** Returns the first error message from a set of `ZodIssue` for a given field. */
function findErrorMessage(
    errors: ZodIssue[],
    field: string | number | symbol,
    language: ReturnType<typeof useAppLanguage>
) {
    const error = errors.find((error) => error.path.join(".").startsWith(field.toString()))?.message
    if (error && Object.values(ParsingErrors).includes(error as ParsingErrors))
        return parsingErrorLocale(error as ParsingErrors, language)
    return error
}

/** Converts a value to a string, unless if it is a null or undefined value. */
function toString(value: any) {
    return value === undefined || value === null ? "" : String(value)
}

/** Returns whether a field is required or not. */
function isRequiredField<T extends ZodTypeAny>(schema: T, field: string | number | symbol) {
    if (!("shape" in schema) || !schema.shape) return !(schema instanceof z.ZodOptional)
    if (!schema.shape[field as any as keyof typeof schema.shape]) return false
    const subfield = String(field).substring(String(field).indexOf("_") + 1)
    if (subfield)
        return isRequiredField(schema.shape[subfield as any as keyof typeof schema.shape], subfield)
    // @ts-ignore
    return !(schema.shape[field] instanceof z.ZodOptional)
}

/** Returns the maximum and minimum values for a field. */
function limitsOfField<T extends ZodTypeAny>(schema: T, field: string | number | symbol) {
    if (!("shape" in schema) || !schema.shape) return { min: undefined, max: undefined }
    if (!schema.shape[field as any as keyof typeof schema.shape])
        return { min: undefined, max: undefined }
    const subfield = String(field).substring(String(field).indexOf("_") + 1)
    if (subfield)
        return limitsOfField(schema.shape[subfield as any as keyof typeof schema.shape], subfield)
    // @ts-ignore
    const min = schema.shape[field].name._def.checks.find(({ kind }) => kind === "min").value
    // @ts-ignore
    const max = schema.shape[field].name._def.checks.find(({ kind }) => kind === "max").value
    return { min, max }
}
