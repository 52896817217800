import React from "react"
import { useAppTranslation } from "hooks/hooks"
import { Container, Text, Title } from "@mantine/core"
import * as classes from "./MaintenanceMode.module.css"

export function MaintenanceMode({ maintenanceMessage }: { maintenanceMessage?: string }) {
    const t = useAppTranslation()

    return (
        <Container className={classes.root}>
            <Title className={classes.title}>{t("core:app_under_maintenance")}</Title>
            <Text className={classes.description}>
                {maintenanceMessage ?? t("core:please_come_back_in_a_few_minutes")}
            </Text>
        </Container>
    )
}
