import { buildingSchema } from "./Building"
import { bankSchema } from "./Bank"
import { categorySchema } from "./Category"
import { unitSchema } from "./Unit"
import { supplierSchema } from "./Supplier"
import { lockerSchema } from "./Locker"
import { parkingSchema } from "./Parking"
import { personSchema } from "./Person"
import { penaltySchema } from "./Penalty"
import { transactionSchema } from "./Transaction"
import { postSchema } from "./Post"
import { budgetSchema } from "./Budget"
import { checkSchema } from "./Check"
import { preparedOtonomFileSchema } from "./PreparedOtonomFile"
import { buildingUserSchema } from "./BuildingUser"
import { invitedUserSchema } from "./InvitedUser"
import { otonomBatchSchema } from "./OtonomBatch"
import { emailTemplateSchema } from "./EmailTemplate"
import { z } from "zod"
import { DeepPartial } from "../helpers"
import { unitCountsSchema } from "./Metadata"
import { requestSchema, requestTagSchema } from "./Request"
import { encryptedBankAccountSchema } from "./EncryptedBankAccount"
import { notificationSentSchema } from "./Notifications"
import { requestEmailDocumentSchema } from "./RequestEmail"
import { logSchema } from "./Log"

/** The set of child collections of a building. */
export const collectionSchema = z.enum([
    "banks",
    "budgets",
    "categories",
    "checks",
    "emailTemplates",
    "invitedUsers",
    "lockers",
    "otonomBatches",
    "parkings",
    "people",
    "penalties",
    "preparedOtonomFiles",
    "posts",
    "suppliers",
    "transactions",
    "unreconciledTransactions",
    "units",
    "users",
    "requests",
    "requestEmails",
    "metadata",
    "requestTags",
    "secretsBankAccounts",
    "notificationsSent",
    "logs",
])
/** The set of child collections of a building. */
export type Collection = z.infer<typeof collectionSchema>

export const objectKindSchema = z.union([collectionSchema, z.enum(["buildings"])])
export type ObjectKind = z.infer<typeof objectKindSchema>

// const firestoreObjectSchema = z.union([
//     bankSchema,
//     budgetSchema,
//     categorySchema,
//     checkSchema,
//     emailTemplateSchema,
//     invitedUserSchema,
//     lockerSchema,
//     otonomBatchSchema,
//     parkingSchema,
//     personSchema,
//     penaltySchema,
//     postSchema,
//     preparedOtonomFileSchema,
//     supplierSchema,
//     transactionSchema,
//     unitSchema,
//     buildingUserSchema,
//     requestSchema,
//     requestEmailDocumentSchema,
//     requestTagSchema,
//     unitCountsSchema,
//     notificationSentSchema,
//     logSchema,
// ])
export type FirestoreObject = TypeByCollection[keyof TypeByCollection]
// z.infer<typeof firestoreObjectSchema>

export const typeByCollectionSchema = z.object({
    banks: bankSchema,
    buildings: buildingSchema,
    budgets: budgetSchema,
    categories: categorySchema,
    checks: checkSchema,
    emailTemplates: emailTemplateSchema,
    invitedUsers: invitedUserSchema,
    lockers: lockerSchema,
    logs: logSchema,
    metadata: unitCountsSchema,
    notificationsSent: notificationSentSchema,
    otonomBatches: otonomBatchSchema,
    parkings: parkingSchema,
    penalties: penaltySchema,
    people: personSchema,
    preparedOtonomFiles: preparedOtonomFileSchema,
    posts: postSchema,
    requestEmails: requestEmailDocumentSchema,
    requestTags: requestTagSchema,
    requests: requestSchema,
    secretsBankAccounts: encryptedBankAccountSchema,
    suppliers: supplierSchema,
    transactions: transactionSchema,
    unreconciledTransactions: transactionSchema,
    units: unitSchema,
    users: buildingUserSchema,
})

/**
 * An object with collection names (e.g., `units` or `banks`) as keys and
 * corresponding objects as values (e.g., a unit for the `units` key).
 */
export type TypeByCollection = z.infer<typeof typeByCollectionSchema>

/**
 * An object with collection names (e.g., `units` or `banks`) as keys and a list of
 * corresponding objects as values (e.g., an array of three units for the `units` key).
 */
export type ArrayOfTypeByCollection = {
    [C in keyof TypeByCollection]: TypeByCollection[C][]
}

/** An object containing a collection name and a partial object of that collection type. */
export type CollectionAndPartialObject = {
    [K in keyof TypeByCollection]: {
        collection: K
        value: DeepPartial<TypeByCollection[K]>
    }
}[keyof TypeByCollection]

/** An object containing a collection name and an object of that collection type. */
export type CollectionAndObject = {
    [K in keyof TypeByCollection]: {
        collection: K
        value: TypeByCollection[K]
    }
}[keyof TypeByCollection]

/**
 * An object containing a collection name and a list of objects of that collection type.
 */
export type CollectionAndObjectList = {
    [K in keyof TypeByCollection]: {
        collection: K
        values: TypeByCollection[K][]
    }
}[keyof TypeByCollection]
