import { Role } from "@appnflat-types/Role"
import {
    doc,
    query,
    onSnapshot,
    collection,
    collectionGroup,
    where,
    QueryFieldFilterConstraint,
} from "firebase/firestore"
import { db } from "firebaseSetup"
import {
    ArrayOfTypeByCollection,
    Collection,
    TypeByCollection,
} from "@appnflat-types/schemas/Collection"
import { store } from "store/store"
import { fiscalYearSelector } from "store/appState"
import {
    emptyCacheOfBuildingData,
    emptyCacheOfFiscalYearData,
    setBuildingFromServer,
    setCollectionFromServer,
} from "store/cache"
import { BuildingUser, buildingUserSchema } from "@appnflat-types/schemas/BuildingUser"
import { Building, buildingSchema } from "@appnflat-types/schemas/Building"
import { Bank } from "@appnflat-types/schemas/Bank"
import { Category } from "@appnflat-types/schemas/Category"
import { Supplier } from "@appnflat-types/schemas/Supplier"
import { Transaction } from "@appnflat-types/schemas/Transaction"
import { Post } from "@appnflat-types/schemas/Post"
import { Unit } from "@appnflat-types/schemas/Unit"
import { Parking } from "@appnflat-types/schemas/Parking"
import { Locker } from "@appnflat-types/schemas/Locker"
import { Person } from "@appnflat-types/schemas/Person"
import { Check } from "@appnflat-types/schemas/Check"
import { InvitedUser } from "@appnflat-types/schemas/InvitedUser"
import { EmailTemplate } from "@appnflat-types/schemas/EmailTemplate"
import { idForCollection } from "@shared/idForCollection"
import { PreparedOtonomFile } from "@appnflat-types/schemas/PreparedOtonomFile"
import { WebCacheCollections, webCacheCollections } from "store/cacheHelpers"
import { UnitCounts } from "@appnflat-types/schemas/Metadata"
import { Penalty } from "@appnflat-types/schemas/Penalty"

/** A set of all the details to fetch. */
let toFetch: {
    buildingRef?: string
    userRole?: Role
    userEmail?: string
    ownersCanAccessFinances?: boolean
    data?: {
        /** The fiscal year this collection is linked to. If `null`, the collection does not depend
         * on the value of the fiscal year (e.g., `posts`, `requests`, etc.). */
        fiscalYear: number
        collections: Collection[]
    }[]
} = {}

/** A list of all the functions to be used to unsubscribe to Firestore. */
let buildingUnsubscribes: {
    unsubscribe: { (): void }
    buildingRef: string
    /** The fiscal year this collection is linked to. If `null`, the collection does not depend
     * on the value of the fiscal year (e.g., `posts`, `requests`, etc.). */
    fiscalYear: number
    collection: Collection
}[] = []

/** A list of functions to unsubscribe to when the user logs out (building docs and user doc). */
const userUnsubscribes: (() => void)[] = []

/** Helper function for `sortEntries`. For any given entry, it returns a function to apply to an
 * object to get a value to sort it by. */
function entryToSortingFunction(collection: WebCacheCollections) {
    switch (collection) {
        case "banks":
        case "categories":
        case "suppliers":
        case "units":
            return function sortByName(entry: Bank | Category | Supplier | Unit) {
                return entry.aid ?? entry.uuid ?? ""
            }
        case "buildings":
            return function sortByName(entry: Building) {
                return entry.name ?? entry.buildingRef ?? ""
            }
        case "transactions":
        case "unreconciledTransactions":
        case "posts":
            return function sortByDate(entry: Transaction | Post) {
                return String(entry.date ?? entry.uuid ?? "")
            }
        case "parkings":
        case "lockers":
            return function sortByNumber(entry: Parking | Locker) {
                return entry.number ?? entry.uuid ?? ""
            }
        case "users":
            return function sortByUserName(entry: BuildingUser) {
                return (entry.uid ?? "").trim()
            }
        case "people":
            return function sortByPersonName(entry: Person) {
                return `${entry.firstName ?? ""} ${entry.lastName ?? ""}`.trim()
            }
        case "checks":
            return function sortByCheckDate(entry: Check) {
                return `${entry.date ?? ""} ${entry.unitAID ?? ""}`
            }
        case "invitedUsers":
            return function sortByEmail(entry: InvitedUser) {
                return entry.email ?? ""
            }
        case "emailTemplates":
            return function sortByKind(entry: EmailTemplate) {
                return entry.kind ?? ""
            }
        case "preparedOtonomFiles":
            return function sortPreparedOtonomFile(entry: PreparedOtonomFile) {
                return entry.kind === "units" ? "all-units" : `supplier-${entry.supplierUUID}`
            }
        case "metadata":
            return function sortId(entry: { id: string }) {
                return entry.id
            }
        case "penalties":
            return function sortPenalty(entry: Penalty) {
                return ("uuid" in entry ? entry.uuid : entry.kind) ?? ""
            }
        default:
            return function sortByUUID(
                entry: Exclude<
                    TypeByCollection[WebCacheCollections],
                    | Bank
                    | Category
                    | Supplier
                    | Building
                    | Transaction
                    | Post
                    | Unit
                    | Parking
                    | Locker
                    | BuildingUser
                    | Person
                    | Check
                    | InvitedUser
                    | EmailTemplate
                    | PreparedOtonomFile
                    | Penalty
                    | UnitCounts
                >
            ) {
                return String(entry.uuid ?? "")
            }
    }
}

function sortEntries<C extends WebCacheCollections>(
    collection: C,
    entries: ArrayOfTypeByCollection[C] | undefined
) {
    if (!entries) return undefined
    const sortFunction = entryToSortingFunction(collection)
    return entries.sort((a, b) => {
        // @ts-ignore
        return sortFunction(a).localeCompare(sortFunction(b), undefined, { numeric: true })
    })
}

function setCacheCollection(data: Partial<ArrayOfTypeByCollection>) {
    for (const collection of webCacheCollections) {
        const values = sortEntries(collection, data[collection])
        if (values) {
            const alreadySeenIds = new Set<string>()
            // Only push unique documents to the store
            const uniqueValues = values.filter((value) => {
                // @ts-ignore
                const id = idForCollection({ value, collection })
                if (!id || alreadySeenIds.has(id)) return false
                else {
                    alreadySeenIds.add(id)
                    return true
                }
            })
            store.dispatch(
                setCollectionFromServer({
                    removeAllCurrentValues: true,
                    collection,
                    // @ts-ignore
                    values: uniqueValues,
                })
            )
        }
    }
}

function resetCacheForBuilding() {
    store.dispatch(emptyCacheOfBuildingData())
}

function resetCacheForFiscalYear() {
    const fiscalYear = fiscalYearSelector(store.getState()) ?? 0
    store.dispatch(emptyCacheOfFiscalYearData({ fiscalYear }))
}

/** Subscribes to a given collection for a given fiscal year. */
function subscribeToCollection(
    buildingRef: string,
    userRole: Role,
    userEmail: string,
    ownersCanAccessFinances: boolean | undefined,
    coll: Collection,
    fiscalYear: number
) {
    if (
        !buildingRef ||
        !fiscalYear ||
        (userRole === Role.resident && coll !== "people") ||
        (userRole === Role.owner && coll === "penalties") ||
        (userRole === Role.owner && coll === "units" && !userEmail) ||
        ([Role.owner, Role.resident].includes(userRole) && coll === "people" && !userEmail) ||
        (userRole === Role.owner &&
            !ownersCanAccessFinances &&
            ["banks", "suppliers", "categories"].includes(coll))
    ) {
        console.debug(`Not subscribing to database collection ${coll}`)
        return
    }

    const filterWithFiscalYear = coll !== "penalties" && coll !== "users" && coll !== "invitedUsers"
    const filters = [
        filterWithFiscalYear && where("fiscalYear", "==", fiscalYear),
        userRole === Role.owner &&
            coll === "units" &&
            where("ownersEmails", "array-contains", userEmail),
        (userRole === Role.owner || userRole === Role.resident) &&
            coll === "people" &&
            where("emails", "array-contains", userEmail),
    ].filter((f): f is QueryFieldFilterConstraint => f !== false)
    console.debug(
        `Subscribing to collection ${coll} for building ${buildingRef} with filters ${JSON.stringify(
            filters
        )}`
    )

    const q = query(collection(db, "buildings", buildingRef, coll), ...filters)
    try {
        const unsubscribe = onSnapshot(
            q,
            function collectionSnapshotReceivedHandler(snap) {
                const localObjects: Record<string, any>[] = []
                for (let i = 0, n = snap.docs.length; i < n; i++) {
                    const data = snap.docs[i]?.data()
                    if (!data || (filterWithFiscalYear && data.fiscalYear !== fiscalYear)) continue
                    localObjects.push(data)
                }
                console.debug(
                    `Saving ${localObjects.length} / ${snap.docs.length} docs for ${coll} to cache`
                )
                setCacheCollection({ [coll]: localObjects })
            },
            (error) => console.error(`Error in fetchCollection for '${coll}'`, error)
        )
        buildingUnsubscribes.push({ unsubscribe, buildingRef, fiscalYear, collection: coll })
    } catch (error) {
        console.error(`An error came up while fetching the building's ${coll}:`, error)
    }
}

/** The list of collections that should be loaded for a building when the user is on that building. */
const collectionsToLoadForBuilding: Collection[] = [
    "units",
    "people",
    "suppliers",
    "parkings",
    "lockers",
    "banks",
    "penalties",
    "categories",
]

/** Unsubscribes from all snapshots. */
function unsubscribeFromAll() {
    console.debug("Unsubscribed from all database listeners")
    for (const unsubscribe of buildingUnsubscribes) {
        try {
            unsubscribe.unsubscribe()
        } catch (error) {
            console.warn(`Problem unsubscribing to a Firestore snapshot`, error)
        }
    }
    buildingUnsubscribes = []
    resetCacheForBuilding()
}

/** Unsubscribes from all snapshots for a given year. */
function unsubscribeFromYear(fiscalYear: number) {
    console.debug(`Unsubscribed from database listeners for year ${fiscalYear}`)
    for (const unsubscribe of buildingUnsubscribes) {
        try {
            if (unsubscribe.fiscalYear === fiscalYear) unsubscribe.unsubscribe()
        } catch (error) {
            console.warn(`Problem unsubscribing to a Firestore snapshot`, error)
        }
    }
    buildingUnsubscribes = buildingUnsubscribes.filter((unsub) => unsub.fiscalYear !== fiscalYear)
    if (toFetch) {
        toFetch = {
            ...toFetch,
            data: toFetch.data?.filter((y) => y.fiscalYear !== fiscalYear),
        }
    }
    resetCacheForFiscalYear()
}

/** Subscribed to all collections we are not yet listening to and should, and removes unnecessary subscriptions. */
function updateSubscriptions() {
    console.debug("About to update database subscriptions...", toFetch)
    if (!toFetch.data || !toFetch.buildingRef || !toFetch.userEmail || !toFetch.userRole) {
        unsubscribeFromAll()
        return
    }
    /** The list of years for which we currently have open connections. */
    const currentlySubscribedYearsSet = new Set<number>()
    for (let i = 0, n = buildingUnsubscribes.length; i < n; i++) {
        const year = buildingUnsubscribes[i]?.fiscalYear
        if (year) currentlySubscribedYearsSet.add(year)
    }
    const currentlySubscribedYears = Array.from(currentlySubscribedYearsSet)

    // Unsubscribe from years we no longer need to fetch.
    currentlySubscribedYears
        .filter((year) => toFetch.data?.every((entry) => entry.fiscalYear !== year))
        .forEach((year) => unsubscribeFromYear(year))

    // Subscribe to all collections we are not currently subscribed to.
    for (const { fiscalYear, collections } of toFetch.data) {
        for (const coll of collections) {
            if (
                buildingUnsubscribes.every(
                    (u) => u.fiscalYear !== fiscalYear || u.collection !== coll
                )
            ) {
                subscribeToCollection(
                    toFetch.buildingRef,
                    toFetch.userRole,
                    toFetch.userEmail,
                    toFetch.ownersCanAccessFinances,
                    coll,
                    fiscalYear
                )
            }
        }
    }
}

export function dbListenerLoadForBuilding(
    buildingRef: string | undefined,
    data: {
        fiscalYear?: number
        userRole?: Role
        userEmail?: string
        ownersCanAccessFinances?: boolean
    }
) {
    try {
        console.info("dbListenerLoadForBuilding called with ", buildingRef, data)
        if (data.fiscalYear) {
            if (!toFetch.data) toFetch.data = []
            if (toFetch.data?.some((d) => d.fiscalYear === data.fiscalYear)) {
                toFetch.data = toFetch.data?.filter((d) => d.fiscalYear !== data.fiscalYear)
            }
            toFetch.data.push({
                fiscalYear: data.fiscalYear,
                collections: collectionsToLoadForBuilding,
            })
        }
        toFetch = { ...toFetch, ...data, buildingRef }
        updateSubscriptions()
    } catch (error) {
        console.error(`An error came up while fetching the building's data:`, error)
    }
}

/** Fetches the building document for the given building. */
function dbListenerLoadBuildingDocument(user: BuildingUser, ref: string) {
    try {
        userUnsubscribes.push(
            onSnapshot(
                doc(db, "buildings", ref),
                function buildingSnapshotReceivedHandler(snap) {
                    const data = buildingSchema.safeParse(snap.data())
                    if (data.success) {
                        store.dispatch(setBuildingFromServer({ building: data.data, ref, user }))
                    }
                    console.debug(`Received the building document:`, snap.data())
                },
                () => {}
            )
        )
    } catch (error) {
        console.error(`An error came up while fetching a building:`, error)
    }
}

/**
 * Fetches all user documents for the current user. This gives us a list of the buildings they
 * are in and their role in each building. Note that this function doesn't actually save anything,
 * but calls on another function to load the building's document and saves all info at once.
 */
export function dbListenerLoadForUser(userUID: string | undefined) {
    console.info("dbListenerLoadForUser called with ", userUID)
    try {
        if (!userUID) {
            userUnsubscribes.forEach((unsub) => unsub())
        } else {
            userUnsubscribes.push(
                onSnapshot(
                    query(collectionGroup(db, "users"), where("uid", "==", userUID)),
                    function userSnapshotReceivedHandler(snap) {
                        for (const doc of snap.docs) {
                            const data = buildingUserSchema.safeParse(doc.data())
                            const ref = doc.ref.parent.parent?.id
                            if (data.success && ref) {
                                dbListenerLoadBuildingDocument(data.data, ref)
                            } else {
                                console.error(
                                    "Error parsing a user document:",
                                    data.error,
                                    doc.data()
                                )
                            }
                        }
                        console.debug(
                            `Received the user documents:`,
                            snap.docs.map((doc) => doc.data())
                        )
                    },
                    () => {}
                )
            )
            console.log(`Currently subscribed to ${userUnsubscribes.length} user fetches`)
        }
    } catch (error) {
        console.error(`An error came up while fetching the user's buildings:`, error)
    }
}
