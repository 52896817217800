const core = {
    documentation: "Documentation",
    cancellation_reason: "Raison de l'annulation",
    pre_authorized_debit: "Prélèvement pré-autorisé",
    send_by_email: "Envoyer par courriel",
    check_reversal_fee: "Frais de chèque retourné",
    please_come_back_in_a_few_minutes: "Veuillez revenir dans quelques minutes.",
    app_under_maintenance: "AppNFlat est en train d'être mis à jour.",
    you_are_already_signed_in: "Vous êtes déjà connecté",
    new_reverse_check: "Chèque retourné",
    notify_owners_by_email: "Avertir le propriétaire par courriel",
    to_add_penalty_first_set_amount_in_penalties_tab:
        "Veuillez d'abord définir la pénalité pour chèque retourné.",
    apply_penalty: "Appliquer la pénalité",
    "new_reverse_wire-transfer": "Tranfert électronique retourné",
    list: "Retour à la liste",
    request_handler: "Gestionnaire de requêtes",
    please_use_on_a_compouter_for_now:
        "Veuillez utiliser AppNFlat sur un ordinateur pour le moment (si vous êtes sur un ordinateur, veuillez vous assurer que la fenêtre de votre navigateur est suffisament large).",
    coming_soon: "Bientôt disponible",
    go_back_home: "Retour à la page d'accueil",
    mobile_not_yet_supported: "Mobile pas encore supporté",
    include_signatures: "Inclure les signatures",
    no_number_of_approvals_required_means_no_signatures_will_be_printed:
        "Comme aucun nombre d'approbation n'a été spécifié dans la section 'Administration', aucune signature ne sera imprimée.",
    printing_check: "Impression du chèque",
    request_approval_tip: "Approbations",
    no_approvals_required: "Pas d'approbation requise",
    signature_set: "Signatures",
    delete_signature: "Effacer la signature",
    number_of_approvals_required: "Nb. d'approbations requises",
    signature: "Signature",
    clear: "Effacer",
    change_signature: "Modifier la signature",
    saving_signature: "Sauvegarde de la signature",
    sign_in_box_above_then_save: "Veuillez signer dans la boite ci-dessous puis sauvegarder.",
    archived_year: "Année fiscale archivée",
    overpaid: "Trop perçu",
    total_overpaid: "Total trop perçu",
    overpaid_on_accounts_receivable: "Trop perçu sur les comptes à recevoir",
    error_loading_email: "Une erreur s'est produite lors du chargement de l'email.",
    attachments: "Pièces jointes",
    add_file: "Ajouter un fichier",
    storage_usage: "Utilisation du stockage",
    reply: "Répondre",
    new_request_from_email: "Nouvelle requête à partir de cet email",
    assign_to_request: "Ajouter l'email à une requête existante",
    spam: "Spam",
    emails_unassigned_to_a_request: "Emails sans requête associée",
    incoming_emails: "Nouveaux Emails",
    inbox: "Boite de réception",
    trashed: "Poubelle",
    no_requests_to_assign_to: "Aucune requête à laquelle associer",
    no_requests_to_merge_with: "Aucune requête avec laquelle fusionner",
    unit_renting: "Unité louant le parking",
    all_units: "Toutes les unités",
    all_parkings: "Tous les parkings",
    all_lockers: "Tous les casiers",
    applies_to: "S'applique à",
    no_monthly_fees: "Pas de charge mensuelle",
    only_you_can_see_this: "Seul vous pouvez voir ceci, personne d'autre dans l'immeuble ne peut.",
    low: "Basse",
    medium: "Normale",
    high: "Elevée",
    open: "Ouverte",
    underway: "En cours",
    closed: "Fermée",
    suggestions: "Suggestions",
    people: "Personnes",
    new_request: "Nouvelle requête",
    priority: "Priorité",
    requestors: "Demandeurs",
    new_email: "Nouvel Email",
    subject: "Sujet",
    new_request_tag: "Nouveau type",
    delete_request: "Effacer la requête",
    request: "Requête",
    merge: "Fusionner des requêtes",
    request_emails: "Emails associés à la requête",
    select_an_email: "Choisir un email",
    read_by_colon: "Lu par:",
    print_check: "Imprimer le chèque",
    to_enable_ensure_payment_day_is_set:
        "Afin d'active le paiement automatique assurez-vous de renseigner le jour du prélèvement.",
    yourself: "Vous",
    from_colon: "De :",
    subject_colon: "Sujet :",
    read_by_color: "Lu par :",
    requests: "Requêtes",
    handler: "Responsable",
    tags: "Types de requêtes",
    color: "Couleur",
    delete_request_tag: "Effacer le type de la requête",
    edit_tags: "Modifier les types",
    request_tags: "Type de la requête",
    and_NUMBER_more: "+ {{number}} autres",
    file_deleted: "Fichier supprimé",
    created_new_fiscal_year: "La nouvelle année fiscale a été créée",
    could_not_find_building: "Impossible de trouver l'immeuble recherché.",
    month_was_reconciled: "Les opérations bancaires ont été conciliées pour ce mois.",
    "+": "+",
    "1_month": "1 mois",
    "1_week": "1 semaine",
    "2_months": "2 mois",
    "2_weeks": "2 semaines",
    "total_accounts-payable": "TOTAL Comptes à payer",
    "total_accounts-receivable": "TOTAL Comptes à recevoir",
    access_billing: "Accéder à la facturation",
    access_has_ended_on_DATE: "Votre accès a expiré le {{date}}",
    access_will_end_on_DATE: "L'accès à AppNFlat expirera le {{date}}",
    account_number: "N° du compte bancaire",
    account_statement: "Relevé de compte",
    account_transfer: "Transfert entre comptes",
    account: "Compte",
    accountant_view_and_write: "Comptable - Consultation & écriture",
    accountant_view_only: "Comptable - Consultation uniquement",
    accounting_allotment: "Affectations comptables",
    accounting_categories: "Comptes",
    accounting_category: "Identifiant comptable",
    accounting_number: "N° du compte",
    accounting: "Comptabilité",
    accounts_payable: "À payer",
    accounts_receivable_and_payable: "Comptes à payer & à recevoir",
    accounts_receivable_payable: "Comptes à recevoir/à payer",
    accounts_receivable: "À recevoir",
    accounts: "Comptes bancaires",
    active: "Actif",
    actual_amount: "Montant courant",
    add_a_new_part: "Ajouter une nouvelle partie",
    add_account: "+ Compte",
    add_cash: "+ Cash",
    add_check: "+ Chèque",
    add_co_owner_resident: "+ Copropriétaire / Locataires",
    add_default_bank_account: "Ajouter un compte en banque par défaut",
    add_email: "+ Courriel",
    add_invoice: "Ajouter une facture",
    add_locker: "+ Casier",
    add_multiple_following_checks: "Ajouter une séquence de chèques",
    add_new: "+",
    add_owner: "+ Copropriétaire",
    add_parking: "+ Parking",
    add_phone: "+ téléphone",
    add_recurring_bill: "+ Facture récurrente",
    add_resident: "+ Locataire",
    add_supplier: "+ Fournisseur",
    add_transaction: "+ Transaction",
    add_user: "+ Utilisateur",
    address: "Adresse",
    administration: "Administration",
    administrator_with_approval: "Administrateur approuvant les paiements",
    administrator: "Gestionnaire d'immeuble",
    all_owners_and_residents_with_a_locker: "Propriétaires et locataires possédant un casier",
    all_owners_and_residents_with_a_parking: "Propriétaires et locataires possédant un parking",
    all_owners: "Tous les propriétaires",
    all_posts: "Toutes les annonces",
    all_residents: "Tous les locataires",
    all_transactions: "Toutes les transactions",
    all_users: "Tous les utilisateurs",
    all: "Tous",
    amount_adjusts_when_selecting_invoices:
        "Le montant s'ajuste lorsque vous sélectionnez des factures à payer.",
    amount_edited: "Montant modifié",
    amount: "Montant",
    monthly_contributions: "Contributions mensuelles",
    apply_new_vote_shares: "Appliquer les nouveaux droits de vote",
    apply_non_retroactively: "Ne pas appliquer rétroactivement",
    appnflat_logo: "Logo de AppNFlat",
    appnflat_payment_due: "Paiement dû pour AppNFlat",
    approval_status: "Statut de l'approbation",
    approval: "Approbations",
    approvals: "Approbations",
    approve: "Approuver",
    approved_and_paid_transactions: "Transactions approuvées et payées",
    approved_and_unpaid_transactions: "Transactions approuvées et en attente de paiement",
    approved_by: "Approuvé par",
    approved_transactions: "Transactions approuvées",
    approved: "Approuvé",
    archive_bank: "Archiver le compte en banque",
    archive_category: "Archiver le compte",
    archive_person: "Archiver la personne",
    archive_this_person: "Archiver cette personne ?",
    archive: "Archiver",
    archived: "Archivé",
    as_administrator: "En tant que gestionnaire d'immeuble",
    as_yourself: "En votre nom propre",
    assets: "Actif",
    author: "Auteur",
    automatic_communications: "Emails automatiques",
    automatic_deposit: "Prélèvement automatique",
    automatic_interest_rate: "Taux d'intérêt pour retard de paiement",
    automatic_penalties: "Pénalités facturées automatiquement",
    automatically_apply: "Facturation automatique",
    available_parameters_are_$unitNumber_and_$amount_and_$link:
        "Vous pouvez inclure le numéro de l'unité ($unitNumber), le montant ($amount) ou un lien ($link).",
    available_parameters_are_$unitNumber_and_$link:
        "Vous pouvez inclure le numéro de l'unité ($unitNumber) ou un lien ($link).",
    back_to_the_app: "Retour vers l’application",
    back: "Retour",
    balance_at_beginning_of_the_year: "Solde au début de l'année",
    balance_beginning_of_year: "Solde au début de l'année",
    balance_sheet: "Bilan",
    balance: "Solde",
    balances_may_take_a_few_minutes_to_update_after_a_transaction_is_added_or_removed:
        "La mise à jour du solde peut prendre quelques minutes lorsque vous ajoutez ou supprimez une transaction.",
    bank_account_for_condo_fees_payments: "Compte des frais de condo",
    bank_account_NAME_currently_has_balance_BALANCE_and_after_otonom_operation_will_have_balance_BALANCE_AFTER_OTONOM:
        "Le solde du compte {{name}} est de {{balance}}. Après le transfer Otonom, le solde sera de {{balanceAfterOtonom}}.",
    bank_account_NAME_currently_has_balance_BALANCE_and_after_transaction_will_have_balance_BALANCE_AFTER_TRANSACTION:
        "Le solde du compte {{name}} est de {{balance}}. Après la transaction, le solde sera de {{balanceAfterTransaction}}.",
    bank_account_name: "Nom du comte en banque",
    bank_account_number: "N° du compte",
    bank_account_type: "Nature du compte",
    bank_account: "Compte en banque",
    bank_accounts: "Comptes bancaires",
    bank_city: "Ville",
    bank_fee: "Frais bancaires",
    bank_institution_number: "N° de l'institution",
    bank_reconciliation: "Conciliation",
    bank_statement: "Relevé bancaire",
    bank_transit_number: "N° de transit",
    bank: "Banque",
    banks: "Banques",
    banned_from_social: "Interdit de poster",
    became_a_resident_on: "Locataire depuis",
    became_an_owner_on: "Copropriétaire depuis",
    beneficiary_bank_account: "Compte bancaire bénéficiaire",
    bill_number: "N° de facture",
    billed_to_unit: "Facturé à l'unité",
    billing_day: "Jour de facturation",
    body: "Texte",
    book_entry: "Écriture comptable",
    budget_application_date: "Date d'application du budget",
    budget_is_being_applied: "Le budget est actuellement appliqué",
    budget_is_not_being_applied: "Le budget est prévisionnel et n'est pas encore appliqué.",
    budget_total_amount: "Total Budget",
    budget: "Budget",
    budgeted_amount: "Montant budgété",
    building_administration: "Administration de l'immeuble",
    building_name: "Nom de l'immeuble",
    building: "Immeuble",
    cadastre_number: "Numéro de Cadastre",
    caller: "Payé par",
    canadian_dollar: "Dollar canadien",
    cancel_this_transaction: "Annuler la transaction",
    cancel_transaction: "Annuler la transaction",
    cancel: "Annuler",
    cancelled: "Annulé",
    capital_payed: "Paiement de capital",
    capital: "Capital",
    cash_flow_statement: "Trésorerie",
    cash_flow: "Trésorerie",
    cash: "Cash",
    category_name: "Nom de du compte",
    category: "Compte",
    cell: "Cellulaire",
    change_file: "Changer de fichier",
    change_name: "Changer le nom",
    change_password: "Changer le mot de passe",
    changing_password: "Changement de mot de passe...",
    charges: "Frais",
    check_number: "N° de chèque",
    check: "Chèque",
    checking_account: "Compte chèque",
    checks_to_be_deposited: "Chèques en attente de dépôt",
    checks: "Chèques",
    child_balances: "États financiers",
    city: "Ville",
    click_to_create_deposit:
        "Cliquez sur le bouton ci-dessous pour créer un nouveau dépôt bancaire.",
    click_to_register_check: "Cliquez sur le bouton ci-dessous afin d'enregistrer un chèque.",
    close_unit_account: "Clôturer le compte de l'unité",
    close: "Fermer",
    closing_balance_shown_on_bank_statement: "Solde de clôture du relevé bancaire :",
    closing_balance: "Solde de clôture",
    co_owner: "Copropriétaire",
    co_owners_access_to_financials: "Accès des copropriétaires aux données financières",
    co_owners: "Copropriétaires",
    common_charges: "Charges communes",
    communications: "Communications",
    condo_fee_invoicing_language: "Langue de facturation des frais de condo",
    condo_fee_payment: "Payment de frais de condo",
    condo_fees_allotment: "Allocation des charges de copropriété",
    condo_fees_to_pay: "Frais de condo à payer",
    condo_fees_to_receive: "Frais de condo à recevoir",
    condo_fees: "Charges communes",
    confirm_sending_to_otonom: "Confirmation de l'envoi à Otonom Solution",
    contact_support_if_issue_persists:
        "Veuillez contacter le support technique si le problème persiste.",
    contact_support: "Contacter le support",
    contact: "Contact",
    contingency_fund: "Fonds de prévoyance",
    continue: "Continuer",
    contributions: "Contributions budgétaires",
    counterpart: "Contrepartie",
    country: "Pays",
    create_an_account: "Créer un compte",
    create_email_template: "Nouveau modèle",
    create_statement: "Générer un relevé",
    creating_account: "Création du compte...",
    credit_card: "Carte de Crédit",
    credit_note: "Note de crédit",
    credit_to_bank_account: "Crédit au compte en banque",
    credit: "Crédit",
    creditor: "Créditeur",
    csv: "csv",
    currency: "Devise",
    dashboard: "Tableau de bord",
    data_is_encrypted_using_industry_standards_such_as_aes_256:
        "Les données sont encryptées selon les standards de l'industrie tels que AES 256.",
    data_is_only_used_to_process_payments_with_otonom:
        "Les données sont utilisée uniquement afin de procéder aux paiements via le logiciel Otonom Solution.",
    date_range: "Période",
    date: "Date",
    debit_card: "Carte de débit",
    debit_to_bank_account: "débité du compte en banque",
    debit: "Débit",
    debtor: "Débiteur",
    default_bank_account_not_specified: "Le compte en banque par défaut n'a pas été défini",
    delete_bank_account_info: "Supprimer les coordonnées bancaires",
    delete_file: "Supprimer le fichier",
    delete_locker: "Supprimer le casier",
    delete_parking: "Supprimer le Parking",
    delete_penalty: "Supprimer la pénalité",
    delete_recurring_bill: "Supprimer la facturation récurrente",
    delete_this_user: "Supprimer cet utilisateur ?",
    delete_transaction_q: "Supprimer la transaction ?",
    delete_transaction: "Supprimer la transaction",
    delete_unit: "Supprimer l'unité",
    delete_user: "Supprimer l'utilisateur",
    delete: "Supprimer",
    deleting_bank_account_info: "Suppression des informations bancaires...",
    deleting: "Suppression en cours...",
    deposit_number: "N° de dépôt",
    deposit: "Dépôt",
    deposited_amount: "Montant déposé",
    deposits_checks_cash: "Dépôts (Chèques & Cash)",
    deposits: "Dépôts",
    description: "Description",
    difference: "Différence",
    direct_deposits: "Dépôts directs",
    do_you_wish_to_remove_locker: "Voulez-vous supprimer ce casier ?",
    do_you_wish_to_remove_parking: "Voulez-vous supprimer ce parking ?",
    documents: "Documents",
    done: "Complété",
    download: "Télécharger",
    due_to_suppliers: "Fournisseurs à payer",
    edit_budget: "Modifier le budget",
    edit_email_template: "Modifier le modèle de courriel",
    edit_owners: "Changer de copropriétaire",
    edit_post: "Modifier le message",
    edit_profile_picture: "Changer la photo de profil",
    edit_user: "Utilisateur",
    edit: "Modifier",
    email_insurance_about_to_expire_frequency:
        "Fréquence de relance pour le renouvellement d'une assurance :",
    email_insurance_about_to_expire_start:
        "Délais pour avertir qu'une assurance doit être renouvelée :",
    email_missing_data_insurance_frequency:
        "Fréquence de relance pour la mise à jour des données d'assurance :",
    email_missing_data_water_heater_frequency:
        "Fréquence de relance pour la mise à jour des données de chauffe-eau :",
    email_recovered: "Courriel rétabli",
    email_templates: "Courriels modèles",
    email_topic: "Sujet du courriel",
    email_verified: "Courriel vérifié",
    email_water_heater_about_to_expire_frequency:
        "Fréquence de relance pour le remplacement d'un chauffe-eau :",
    email_water_heater_about_to_expire_start:
        "Délais pour avertir qu'un chauffe-eau doit être remplacé :",
    email: "Courriel",
    emergency_contact: "Contact d'urgence",
    empty_if_no_automatic_payment: "Vide si pas de paiement automatique",
    empty_if_no_end: "Vide si pas de fin",
    end_date: "Date de clôture",
    end_of_warranty: "Fin de garantie",
    ensure_difference_is_zero: "Assurez-vous que l'écart soit nul.",
    enter_a_name: "Entrez un nom",
    enter_a_valid_date: "Veuillez entrer une date valide (postérieure à ce jour).",
    enter_a_valid_email: "Entrez un courriel valide.",
    enter_a_valid_number: "Entrez un nombre valide (jusqu'à deux chiffres après la virgule)",
    enter_a_valid_phone_number: "Entrez un numéro de téléphone valide",
    enter_a_valid_value: "Entrez une valeur.",
    enter_your_password: "Entrez votre mot de passe.",
    error_regenerating_otonom_csv:
        "Une erreur s'est produite lors de la génération du fichier csv pour Otonom Solution.",
    error: "Erreur",
    essential_information_missing: "Information manquante",
    euro: "Euro",
    everyone_in_the_building: "Tous les copropriétaires et locataires",
    excel: "Excel",
    expense_account: "Compte de dépenses",
    expense_category: "Compte de dépense",
    expense: "Dépense",
    expenses: "Dépenses",
    export_all_data: "Exporter toutes les données",
    export_all_transactions: "Exporter toutes les transactions",
    export: "Exporter",
    fax: "Fax",
    fee_payment: "frais de condo",
    file_uploaded: "Fichier téléchargé",
    financial_statement_section: "États Financiers (section)",
    financial_statements_parameters: "Paramètres des états financiers",
    financial_statements: "États Financiers",
    financials: "Finances",
    first_name: "Prénom",
    fiscal_year_end_k: "Clôture fiscale : {{date}}",
    fiscal_year_end: "Clôture fiscale",
    fiscal_year_k: "Année fiscale {{year}}",
    fiscal_year: "Année fiscale",
    floor_NUMBER: "Étage {{number}}",
    floor: "Étage",
    fraction: "Fraction",
    free_trial_ends_on_DATE: "La période d'essai se termine le {{date}}",
    free_trial: "Période d'essai",
    from: "De",
    full_name: "Nom complet",
    full_or_partial_payment: "Payment complet ou partiel",
    full_payment: "Payment complet",
    gender: "Sexe",
    generate_financial_report: "Rapport financier",
    generate_otonom_csv_for_units: "Encaisser le paiement des unités via Otonom Solution",
    generate_otonom_csv: "Envoyer le paiement à Otonom Solution",
    generates_a_csv_file_for_otonom_to_collect_unit_payments:
        "Envoie l'encaissement des unités inscrite au prélèvement automatique à Otonom Solution.",
    generating_otonom_csv: "Génération du fichier csv pour Otonom Solution.",
    grace_period_in_days: "Période de grâce (en jours)",
    grace_period: "Période de grâce",
    history: "Historique",
    home: "Domicile",
    i_agree_with_the_privacy_policy_and_terms_of_services:
        "J'accepte la politique de confidentialité et les conditions d'utilisation",
    i_agree_with_the_privacy_policy: "J'accepte la politique de confidentialité.",
    i_agree_with_the_terms_of_services: "J'accepte les conditions générales d'utilisation.",
    if_no_budget_units_will_not_be_automatically_billed:
        "Sans budget, les unités ne seront pas facturées automatiquement chaque mois.",
    if_no_default_bank_account_units_will_not_automatically_pay:
        "Tant que vous n'avez pas défini un compte en banque par défaut, le payment automatique des unités n'est pas activé.",
    if_you_did_not_change_your_email_please_immediately_change_your_password:
        "Si vous n'avez pas modifié votre courriel, veuillez changer immédiatement votre mot de passe.",
    include: "Inclure:",
    income_statement: "Compte de résultat",
    income: "Revenus",
    incomplete: "Incomplet",
    informations: "Informations",
    insert_title: "Insérer un titre",
    insert_unit_link: "Insérer le lien de l'unité",
    insert_unit_number: "Insérer le numéro de l'unité",
    installation_date: "Date d'installation",
    insurance_about_to_expire_reminder: "Rappel pour assurance expirant bientôt",
    insurance_about_to_expire: "Assurance expirant bientôt",
    insurance_certificate: "Certificat",
    insurance_incomplete_reminder: "Rappel pour information incomplète sur l'assurance",
    insurance_info_incomplete: "Information incomplète sur l'assurance",
    insurance_invalid_or_missing_details:
        "L'assurance n'est pas valide ou certains détails manquent.",
    insurance_provider: "Compagnie",
    insurance_reminders: "Relance pour les assurances",
    insurance: "Assurance",
    insurance_end_date: "Date de fin",
    interest_rate_p: "Taux d'intérêt (%)",
    interest_rate: "Taux d'intérêt",
    interests_payed: "Payment d'intérêts",
    interests_received: "Versement d'intérêts",
    inv: "Fact.",
    invalid_value: "Valeur invalide",
    investment_account: "Compte d'investissement",
    invite_bulk_users: "Inviter un grand nombre d'utilisateurs",
    invited_as_ROLE: "Invité en tant que {{role}}",
    invited_users: "Utilisateurs invités",
    invited: "Invité",
    inviting_users: "Invitation des utilisateurs en cours...",
    invoice_file: "Document de facture",
    invoice_from_supplier: "Facturé au fournisseur",
    invoice_not_found: "Facture introuvable",
    invoice_NUMBER: "Facture {{number}}",
    invoice: "Facture",
    invoiced_k: "Facturé : {{credits}}",
    invoiced: "Facturé",
    invoices: "Factures",
    invoicing_threshold: "Seuil de facturation : ",
    journal_of_transactions: "Journal des transactions",
    keep_balance_with_old_owner: "Gardez le solde avec l'ancien propriétaire",
    keyboard_shortcuts: "Raccourcis clavier",
    kind: "Type",
    last_name: "Nom de famille",
    late_payment_penalty: "Pénalité de retard de paiement",
    leave_building: "Quitter le bâtiment",
    legal: "Légal",
    level: "Niveau",
    liabilities: "Passif",
    liability_insurance_coverage: "Couverture responsabilité",
    list_of_bank_accounts: "Liste des comptes en banque",
    list_of_checks: "Liste des chèques",
    list_of_co_owners_and_residents: "Liste des copropriétaires et locataires",
    list_of_lockers: "Liste des casiers",
    list_of_parkings: "Liste des Parkings",
    list_of_suppliers: "Liste des fournisseurs",
    list_of_units: "Liste des unités",
    loading_and_decrypting_bank_account_info:
        "Chargement et décryptage des informations bancaires...",
    loading_report: "Téléchargement du rapport",
    loading: "Chargement...",
    locker_k: "Casier {{number}}",
    locker_number: "N° de casier",
    locker: "Casier",
    lockers: "Casiers",
    low_balance_threshold: "Solde bancaire d'alerte",
    man: "Homme",
    manage_users: "Gestion des utilisateurs",
    missing_budget: "Les frais de condo n'ont pas été budgétés",
    month: "Mois",
    monthly_cash_flows: "Flux de trésorerie mensuels",
    monthly_exceptional_contribution: "Contribution exceptionnelle",
    monthly_fee: "Frais mensuels",
    monthly_fees: "Frais mensuels",
    monthly: "Une fois par mois",
    multiple: "Multiple",
    my_posts: "Mes annonces",
    name_is_same_across_buildings_and_is_shown_to_other_users_no_personal_information_is_sent_to_gravatar:
        "Votre nom est le même dans tous les immeubles et est affiché aux autres utilisateurs. Aucune information personnelle n'est envoyée à Gravatar.",
    NAME_operating_account: "{{name}} (compte d'opération)",
    name: "Nom",
    never: "Jamais",
    new_bank: "Nouvelle banque",
    new_categories: "Nouveaux comptes",
    new_charge: "Nouveau frais",
    new_checks: "Nouveaux chèques",
    new_co_owner: "Nouveau propriétaire",
    new_condo_fees: "Nouvelles charges de copropriété",
    new_condo: "Paiement de",
    new_customFee: "Nouvelle pénalité",
    new_deposit: "Nouveau dépôt",
    new_email_template: "Nouveau modèle de courriel",
    new_emailTemplates: "Nouveau modèle de courriel",
    new_locker: "Nouveau casier",
    new_lockers: "Nouveaux casiers",
    new_parking: "Nouveau parking",
    new_parkings: "Nouveaux Parkings",
    new_password: "Nouveau mot de passe",
    new_penalties: "Nouvelles pénalités",
    new_penalty: "Nouvelle pénalité",
    new_person: "Nouvelle personne",
    new_post: "Nouvelle annonce",
    new_posts: "Nouvelles annonces",
    new_supplier: "Nouveau fournisseur",
    new_suppliers: "Nouveaux fournisseurs",
    new_template: "Nouveau modèle",
    new_transaction: "transaction",
    new_unit: "Nouvelle unité",
    new_units: "Nouvelles Unités",
    new: "Nouveau",
    newF: "Nouvelle",
    no_budget: "Veuillez sélectionner un budget.",
    no_currency_specified_please_contact_support: "Pas de devise. Veuillez contacter le support.",
    no_data: "Pas de données",
    no_file_uploaded: "Pas de fichier téléchargé",
    no_locker: "Pas de casier",
    no_more_invoices_to_pay: "Aucune autre facture à payer",
    no_more_to_show: "Fin des transactions",
    no_one: "Personne",
    no_parent: "Pas de compte parent",
    no_parking: "Pas de parking",
    no_pending_invoice: "Vous n'avez pas de factures à payer.",
    no_time_zone_specified_please_contact_support:
        "Aucun fuseau horaire. Veuillez contacter le support.",
    no_transactions_to_show: "Aucune transaction",
    no_transactions_with_approval: "Il n'y a pas de paiement en attente d'approbation",
    no: "Non",
    none: "Aucun",
    notes: "Notes",
    number_of_checks: "Nombre de chèques",
    number_of_months_where_unit_has_unpaid_balance:
        "Nombre de mois depuis que l'unité a un solde impayé",
    number_of_weeks_after_which_to_send_email:
        "Nombre de semaines avant relance pour frais de condo impayés",
    number: "N°",
    ok: "Ok",
    old_password: "Ancien mot de passe",
    once_budget_applied_cannot_be_changed: "Une fois appliqué, le budget ne peut être modifié",
    open_file: "Ouvrir le fichier",
    open_invoice: "Ouvrir la facture",
    other_exports: "Autres exports",
    other: "Autre",
    otonom_disabled_as_no_bank_account_specified:
        "Otonom Solution ne peut être utilisé tant que vous n'avez pas spécifié les coordonnées bancaires du destinataire.",
    otonom_id_but_no_uploaded_bank_account_details:
        "Les informations du compte en banque manquent.",
    otonom_id: "N° Otonom Solution",
    otonom_integration_is_disabled_go_to_admin_to_activate_it:
        "[Otonom Solution](https://www.otonomsolution.com) est un outil pour encaisser des paiements et effectuer des virements en ligne.\n\nSi vous voulez utilisez Otonom Solution assurez-vous d'abord d'avoir un compte avec eux.\n\nSi vous êtes déjà client d'Otonom Solution, veuillez visiter la section 'Administration / Immeuble' pour activer Les fonctionnalités correspondantes.",
    otonom_integration: "Activation d'Otonom",
    otonom_solution: "Otonom Solution",
    otonom: "Otonom",
    overview: "Aperçu",
    owned_units: "Unités détenues",
    owner: "Copropriétaire :",
    owners_and_residents: "Propriétaires & locataires",
    owners_will_be_notified_by_email: "Les propriétaires seront notifiés par courriel.",
    owners: "Propriétaires",
    page_not_found: "Page introuvable",
    paid_k: "Payé : {{debits}}",
    paid: "Payé",
    parking_k: "Parking {{number}}",
    parking_number: "N° de parking",
    parking: "Parking",
    parkings: "Parkings",
    partial_payment: "Payment partiel",
    password: "Mot de passe",
    payment_day: "Jour de paiement",
    payment_from_unit: "Payment de l'unité",
    payment_method: "Mode de paiement",
    payment_status: "Statut du paiement",
    payment_to_supplier: "Payment au fournisseur",
    payment: "Paiement",
    payments: "Paiements",
    pdf: "pdf",
    penalties_and_fees: "Frais & Pénalités",
    penalties: "Pénalités",
    penalty_amount: "Montant de la pénalité",
    penalty_description: "Description de la pénalité",
    penalty_name: "Nom de la pénalité",
    penalty: "Pénalité",
    pending_approval: "Approbation en attente",
    pending_transactions: "Transactions en attente",
    pending: "En attente",
    person: "Personne",
    phone_number: "N° de tél.",
    phone_type: "Type du téléphone",
    phone: "Tél",
    phones: "Téléphones",
    please_contact_support_as_soon_as_possible_to_get_help:
        "L'application ne peut pas fonctionner, veuillez contacter le support.",
    please_fill_the_form: "Veuillez compéter le formulaire",
    please_set_language_to_be_able_to_generate_reports:
        "Veuillez définir la langue afin de générer les rapports.",
    policy_number: "N° de police",
    post_content: "Contenu de l'annonce",
    post_has_no_content: "Message sans contenu",
    post_title: "Titre de l'annonce",
    posted_on: "Posté le",
    preferred_language: "Langue préférée",
    previous_deposits: "Dépôts précédents",
    previous_transfer: "Virements précédents",
    previously_generated_otonom_files: "Paiements Otonom Solution envoyés ces 30 derniers jours",
    privacy_policy: "Politique de confidentialité",
    problem_generating_otonom_csv_for_following_accounts:
        "Une erreur s'est produite lors de la génération des données Otonom Solution pour les comptes suivants :",
    problem_generating_otonom_csv_for_following_units:
        "Une erreur s'est produite, les données Otonom Solution n'ont pas été générées pour la ou les unités suivantes :",
    problem_generating_otonom_csv_for_n_transactions:
        "Une erreur s'est produite, les données Otonom Solution n'ont pas été générées pour {{number}} transactions.",
    problem_generating_part_of_otonom_csv:
        "Une erreur s'est produite, certaines données Otonom Solution n'ont pas été générées.",
    problem_getting_list_of_buildings: "Un problème est survenu lors de l'accès aux immeubles.",
    problem_reconciling_transaction:
        "Une erreur s'est produite lors de la conciliation de la transaction",
    problem_saving_supplier: "Une erreur s'est produite lors de la sauvegarde du fournisseur",
    // problem_signing_in: "Un problème est survenu lors de la connexion.",
    profile_pictures_are_from_gravatar: "Les photos de profil proviennent de Gravatar",
    profile: "Profil",
    profit_and_loss: "Profit et perte",
    ready_to_send_to_otonom: "Prêt à envoyer à Otonom Solution",
    rec: "Rec.",
    receiving_account: "Compte bénéficiaire",
    recipients_k: "Destinataires: {{recipients}}",
    recipients: "Destinataires",
    reconciled: "Concilié",
    recurring_bill: "+ Facture récurrente",
    recurring_bills: "Factures récurrentes",
    recurring_end_date: "Date de fin",
    recurring_payment_amount: "Montant du paiement récurent",
    recurring_payment_name: "Nom du paiement récurent",
    regenerate: "Régénérer",
    register_check: "Enregistrer un chèque",
    registered_checks: "Chèques enregistrés",
    reject: "Rejeter",
    rejected_by: "Rejeté par",
    rejected_by2: "Rejeté par :",
    rejected_transactions: "Transactions dont le paiement a été rejeté",
    rejected: "Rejeté",
    remaining_balance_processing: "Traitement de la balance restante",
    removed_amount: "Montant retiré",
    reneging_co_owners_accounts: "Comptes des copropriétaires en souffrance",
    rented: "Loué",
    person_renting: "Loué par",
    report_language_not_specified: "Langue de facturation des frais de condo indéfinie.",
    reports: "Rapports",
    nudge_approval: "Envoyer un rappel",
    request_approval: "À approuver",
    required_field_missing: "Il manque un champ obligatoire",
    reserve_fund: "Fonds de réserve",
    reset_changes: "Annuler les changements",
    resident_with_access_to_financial_details: "Locataire avec accès aux informations financières",
    resident: "Locataire",
    residential_units: "Unités résidentielles",
    residents: "Locataires",
    revenue_account: "Compte de recette",
    revenues: "Revenus",
    role: "Type",
    roles: "Types",
    save_and_add_another: "Sauvegarder & Ajouter",
    save_transactions: "Sauvegarder les transactions",
    save: "Sauvegarder",
    saving_account: "Compte d'Épargne",
    saving: "Sauvegarde en cours...",
    search_list: "Recherche",
    search: "Rechercher",
    sections_to_include: "Sections à inclure",
    select_date_on_which_to_start_applying_budget_retroactively:
        "Date à laquelle le nouveau budget entre en vigueur",
    select_file: "Sélectionner un fichier",
    select_transactions: "Sélectionner les transactions",
    self_insurance_fund: "Fonds d'auto assurance",
    send_email_on_check_save: "Confirmer par courriel la réception de chèques",
    send_email_to_recipients: "Envoyer par courriel",
    send_email_to_units_with_unpaid_balance:
        "Envoyer un courriel de rappel pour frais de condo impayés.",
    send_to_otonom_for_NAME: "Payer {{name}}",
    send_to_otonom: "Envoyer à Otonom Solution",
    send_unit_payments_to_otonom:
        "Cliquez sur le bouton ci-dessous pour envoyer à Otonom Solution le paiement des unités utilisant le virement automatique.",
    send_unit_statement: "Envoyer l'état de compte",
    sending_to_otonom: "Envoi en cours à Otonom Solution...",
    sent_to_otonom: "envoyé à Otonom Solution",
    set_language: "Définir la langue",
    settings: "Réglages généraux",
    shortcuts: "Raccourcis",
    show_help: "Afficher l'aide",
    sign_in: "Se connecter",
    sign_out: "Se déconnecter",
    sign_up: "Créer un compte",
    signing_in: "Connection...",
    signing_out: "Déconnexion...",
    start_date: "Date de début",
    start_new_budget: "Créer un nouveau budget",
    start_new_fiscal_year: "Commencer une nouvelle année fiscale",
    starting_balance: "Solde au début",
    state: "Province",
    status: "Status",
    sterling_pound: "Livre Sterling",
    street: "Rue",
    success: "Opération complétée avec succès",
    supplier_name: "Nom du fournisseur",
    supplier_payment: "Payment de fournisseurs",
    supplier_statement: "Relevé d'un fournisseur",
    supplier_transactions_ready_to_send_to_otonom:
        "Paiements de fournisseurs à envoyer à Otonom Solution",
    supplier: "Fournisseur",
    suppliers_SUPPLIERS_have_transactions_to_send_to_otonom: "{{suppliers}}",
    suppliers: "Fournisseurs",
    terms_of_services: "Conditions de service",
    threshold_for_automatic_billing: "Seuil de facturation automatique",
    time_zone: "Fuseau horaire",
    title: "Titre",
    to_add_or_remove_units_and_parkings_please_contact_support:
        "Pour ajouter ou enlever une unité ou un parking, veuillez contacter le support.",
    to_enroll_unit_in_automatic_payment_enter_bank_account_details:
        "Afin d'activer le prélèvement automatique, veuillez renseigner les coordonnées bancaires du payeur.",
    to: "À",
    toggle_navigation: "Menu",
    total_assets: "TOTAL Actif",
    total_capital_and_liabilities: "TOTAL Capital & Passif",
    total_capital: "TOTAL Capital",
    total_expense_year_to_Date: "Total Dépenses",
    total_expenses: "TOTAL Dépenses",
    total_income_year_to_Date: "Total Revenues",
    total_k: "Total : {{total}}",
    total_liabilities: "TOTAL Passif",
    total_revenues: "TOTAL Revenus",
    total: "Total",
    transaction: "Transaction",
    transactions_export: "Export des transactions",
    transactions: "Transactions",
    transfer_balance_to_new_owner: "Transférer le solde au nouveau propriétaire",
    transfer_to_new_owner_or_keep_on_old_owner:
        "Souhaitez-vous transférer ce solde au nouveau propriétaire ?",
    transfers: "Transferts",
    unit_k: "Unité {{number}}",
    unit_NUMBER_sold: "Unité {{number}} (vendue)",
    unit_number: "N° d'unité",
    unit_statement: "Relevé d'une unité",
    unit_transactions_ready_to_send_to_otonom: "Paiements d'unités à envoyer à Otonom Solution",
    unit_unpaid_balance_reminder: "Rappel pour frais de condo impayés",
    unit: "Unité",
    united_states_dollar: "Dollar américain",
    units_collection: "Encaissement venant des copropriétaires",
    units_parkings_and_lockers: "Unités, parkings et casiers",
    units_paying_by_automatic_wire_transfers: "Unités payant par virement",
    units_UNITS_have_transactions_to_send_to_otonom: "Unités {{units}}",
    units_with_unpaid_balance: "Unités avec un solde impayé",
    units_without_insurance: "Unités sans assurance",
    units: "Unités",
    unknown_action: "Action inconnue",
    unknown_building: "Immeuble inconnu",
    unknown_error: "Erreur inconnue",
    unknown_name: "Nom inconnu",
    unknown_role: "Role inconnu",
    unknown: "Inconnu",
    unpaid_balance_after_k_weeks: "Frais de condo impayés depuis {{k}} weeks",
    unpaid_balance_reminder: "Rappel pour frais de condo impayés",
    update_bank_account: "Mettre à jour le compte en banque",
    update_password: "Mise à jour du mot de passe",
    update_payment_info: "Mettre à jour",
    update: "Modifier",
    upload_file: "Téléverser un fichier",
    upload: "Télécharger",
    uploaded_bank_account_details_but_no_otonom_id:
        "Le compte en banque n'a pas d'identifiant Otonom Solution.",
    uploading: "Téléchargement en cours...",
    users: "Utilisateurs",
    v_owners_and_residents_with_a_locker: ", Propriétaires et locataires possédant un casier",
    v_owners_and_residents_with_a_parking: ", Propriétaires et locataires possédant un parking",
    v_owners: ", Propriétaires",
    v_residents: ", Locataires",
    vehicle_license_plate: "N° d'immatriculation",
    verification_code: "Code de vérification",
    view_of_montreal: "Vue de Montréal",
    view_units_paying_by_automatic_wire_transfers:
        "Cliquez sur le bouton ci-dessous pour accéder à la liste des unités payant par virement automatique.",
    virgule: ",",
    vote_share_total_is_not_100: "La somme des droits de vote n'est pas égale à 100%.",
    vote_share: "% de vote",
    vote_shares: "Droits de vote",
    waiting_approval: "En attente d'approbation",
    water_heater_about_to_expire_reminder: "Rappel pour garantie du chauffe-eau expirant bientôt",
    water_heater_about_to_expire: "Garantie du chauffe-eau expirant bientôt",
    water_heater_incomplete_reminder: "Rappel pour information incomplète sur le chauffe-eau",
    water_heater_info_incomplete: "Informations incomplètes sur le chauffe-eau",
    water_heater_invalid_or_missing_details: "Certains détails manquent.",
    water_heater_label: "Étiquette",
    water_heater_reminders: "Relance pour les chauffe-eaux",
    water_heater: "Chauffe-eau",
    water_heaters_to_be_replaced: "Chauffe-eau à remplacer",
    weekly: "Une fois par semaine",
    welcome_back: "Bienvenue",
    wire_transfer: "Virement",
    wire_transfers: "Virements",
    woman: "Femme",
    work: "Travail",
    yearly: "Une fois par an",
    yes: "Oui",
    your_buildings: "Vos immeubles",
    zip_code: "Code postal",
} as const

export default core
