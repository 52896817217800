/**************************************
 * CACHE HELPERS
 **************************************/

import { DeepPartial } from "@appnflat-types/helpers"
import { TypeByCollection, collectionSchema } from "@appnflat-types/schemas/Collection"
import { BuildingUser } from "@appnflat-types/schemas/BuildingUser"

export const webCacheCollections: WebCacheCollections[] = [
    "buildings",
    ...collectionSchema.options.filter(
        (o): o is Exclude<WebCacheCollections, "buildings"> => o !== "notificationsSent"
    ),
]
/** The collections that are cached in the web app. */
export type WebCacheCollections = Exclude<
    keyof TypeByCollection,
    "notificationsSent" | "secretsBankAccounts"
>

/** A cache entry. */
export type CacheEntry<T extends WebCacheCollections> = {
    /** Whether the object should be deleted in the database. */
    delete?: boolean
    /** A set of edits that have not yet been pushed to the database. */
    edits?: DeepPartial<TypeByCollection[T]>
} & (
    | {
          /** The original as it currently is in the database. */
          original: TypeByCollection[T]
      }
    | {
          /** The details of an object that was created and isn't yet saved to the database. */
          created: TypeByCollection[T]
      }
) &
    (T extends "buildings"
        ? {
              /** The building ref (i.e., the document id in Firestore). */
              ref: string
              /** The user's document in the building. */
              user: BuildingUser
          }
        : never)

/**
 * The type of the cache.
 * The keys of each record is as defined in `{@link idForCollection}`.
 */
export type Cache = {
    [T in WebCacheCollections]: Record<string, CacheEntry<T>>
}

/** The initial state of the cache. */
export const initialState: Cache = {
    banks: {},
    buildings: {},
    budgets: {},
    categories: {},
    checks: {},
    emailTemplates: {},
    invitedUsers: {},
    lockers: {},
    logs: {},
    metadata: {},
    otonomBatches: {},
    parkings: {},
    penalties: {},
    people: {},
    preparedOtonomFiles: {},
    posts: {},
    requestEmails: {},
    requestTags: {},
    requests: {},
    suppliers: {},
    transactions: {},
    unreconciledTransactions: {},
    units: {},
    users: {},
}

/**************************************
 * HELPERS
 **************************************/

/** Merges a cache entry, giving priority to edited fields over their original value. */
export function mergeEntry<C extends keyof Cache, T extends CacheEntry<C> = CacheEntry<C>>(
    entry: T
): TypeByCollection[C] {
    if ("original" in entry) return { ...(entry.original ?? {}), ...(entry.edits ?? {}) }
    else return { ...(entry.created ?? {}), ...(entry.edits ?? {}) }
}
