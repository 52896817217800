import { z, ZodTypeAny } from "zod"
import { RecursiveKeysOfUnion } from "../helpers"

type AddOpenAPIParams = {
    type?: "string"
    pattern?: string
    maxLength?: number
    minLength?: number
}

/** Calls the `.openapi()` function on a zod object if it exists, skips calling it otherwise. */
export function addOpenAPI<Z extends z.ZodType>(toExtend: Z, params: AddOpenAPIParams): Z {
    return "openapi" in toExtend && typeof toExtend.openapi === "function"
        ? toExtend.openapi(params)
        : toExtend
}

/**
 * Creates a custom zod object with regex that supports both the OpenAPI field and a template
 * literal custom TypeScript type.
 *
 * @example
 * // Creates a zod object with type `unit:${number}`. When used in an OpenAPI schema, it will
 * // have the following properties:
 * // - type: "string"
 * // - pattern: "^unit:[0-9]+$"
 * zodRegex<`unit:${number}`>(/^unit:[0-9]+$/)
 */
export function zodRegex<TemplateLiteral extends string>(
    pattern: RegExp,
    { message, max, min }: { message?: string; max?: number; min?: number } = {}
) {
    const zodType = z
        .custom<TemplateLiteral>(
            (v) =>
                typeof v === "string" &&
                pattern.test(v) &&
                (!max || v.length <= max) &&
                (!min || v.length >= min),
            { message }
        )
        .and(z.string())

    return addOpenAPI(zodType, {
        type: "string",
        maxLength: max,
        minLength: min,
        pattern: pattern.source,
    })
}

/** Returns the maximum and minimum values for a field. */
export function limitsOfField<T extends ZodTypeAny>(
    schema: T,
    field: RecursiveKeysOfUnion<z.infer<T>>
) {
    if (!("shape" in schema) || !schema.shape) return { min: undefined, max: undefined }
    if (!schema.shape[field as any as keyof typeof schema.shape])
        return { min: undefined, max: undefined }
    const subfield = String(field).substring(String(field).indexOf("_") + 1)
    // FIXME: handle unwrap?
    if (subfield)
        return limitsOfField<any>(
            schema.shape[subfield as any as keyof typeof schema.shape],
            subfield
        )
    // @ts-ignore
    const min: number | undefined = schema.shape[field].name._def.checks.find(
        // @ts-ignore
        ({ kind }) => kind === "min"
    ).value
    // @ts-ignore
    const max: number | undefined = schema.shape[field].name._def.checks.find(
        // @ts-ignore
        ({ kind }) => kind === "max"
    ).value
    return { min, max }
}
