import { auth } from "firebaseSetup"
import { showErrorNotification } from "logic/notifications"
import createClient, { Middleware } from "openapi-fetch"
import { paths } from "openapi/backend"
import { baseAPIUrl } from "./baseURL"
import { SimpleTKey } from "logic/textDescriptions/translate"
import { TranslationEntry } from "hooks/hooks"

const client = createClient<paths>({ baseUrl: baseAPIUrl })

function contentType(body: any): "application/json" | "multipart/form-data" | undefined {
    if (!body || typeof body !== "object") return undefined
    try {
        const stringified = JSON.stringify(body)
        if (stringified.startsWith("{") || stringified.startsWith("[")) return "application/json"
        else return undefined
    } catch {
        return undefined
    }
}

const requestMiddleware: Middleware = {
    onRequest: async function onRequest({ request }) {
        const token = await auth.currentUser?.getIdToken()
        request.headers.set("Authorization", `Bearer ${token}`)
        if (request.method === "POST" || request.method === "PUT") {
            const type = contentType(request.body)
            if (type) request.headers.set("Content-Type", type)
        }
        return request
    },
}

client.use(requestMiddleware)
export const { GET, POST, PUT, DELETE } = client

export function requestErrorHandler(
    errorTitle: SimpleTKey | "silentError" | TranslationEntry,
    onError?: (error: any) => void
) {
    return function _errorHandler(error: any) {
        console.error(`Caught error in request: `, error)
        if (errorTitle !== "silentError") showErrorNotification({ title: errorTitle }, error)
        if (onError) onError(error)
    }
}

export function requestResultHandler(errorTitle: SimpleTKey | "silentError" | TranslationEntry) {
    return function _defaultHandler<T extends object | void>(result: T): T {
        if (result && "error" in result && result.error) {
            console.error(`Caught error in request: `, result.error)
            if (errorTitle !== "silentError")
                showErrorNotification({ title: errorTitle }, result.error)
        }
        return result
    }
}

export async function requestHandler<R extends object | void, E extends object | void>({
    request,
    errorTitle,
    onError,
    onResult,
    onFinally,
}: {
    request: Promise<{ data?: R; error?: E }>
    errorTitle: SimpleTKey | "silentError" | TranslationEntry
    onError?: (error: E | undefined | null) => void
    onResult?: (result: R) => void
    onFinally?: () => void
}) {
    const result = await request.catch((error) => {
        onError?.(error)
        requestErrorHandler(errorTitle, onError)(error)
        onFinally?.()
    })
    if (!result) return
    if ("error" in result && result.error) {
        onError?.(result.error)
        requestResultHandler(errorTitle)(result)
    }
    if ("data" in result && result.data) onResult?.(result.data)
    onFinally?.()
    return result
}
