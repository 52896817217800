import React from "react"
import { Button, Container, Title, Paper, PasswordInput } from "@mantine/core"
import { auth } from "../../firebaseSetup"
import { signInWithEmailAndPassword } from "firebase/auth"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppTranslation } from "hooks/hooks"
import ResetPasswordPopup from "./ResetPasswordPopup"
import { useForm } from "hooks/useForm"
import { z } from "zod"
import { WTextInput } from "components/Inputs/WTextInput"
import * as classes from "./SignIn.module.css"
import { setLoadingState, showErrorNotification } from "logic/notifications"

const signInFormSchema = z.object({
    email: z.string().email(),
    password: z.string(),
})

const localTranslations = {
    signingIn: {
        en: "Signing in...",
        fr: "Connexion...",
    },
}

export function Component() {
    const t = useAppTranslation()
    const navigate = useNavigate()
    const path = useLocation()

    async function signIn(formResult: z.infer<typeof signInFormSchema>) {
        setLoadingState({ message: localTranslations.signingIn })
        try {
            const localUser = await signInWithEmailAndPassword(
                auth,
                formResult.email,
                formResult.password
            )
            setLoadingState(false)
            if (localUser && ["/", "/signin"].includes(path.pathname))
                navigate("/dashboard", { replace: true })
        } catch (error) {
            console.error("Error signing in", error)
            setLoadingState(false)
            showErrorNotification({ title: "problem_signing_in" }, error)
        }
    }
    const form = useForm(signInFormSchema, signIn, {
        silentFailSubmit: true,
        clearOnSubmit: false,
    })

    return (
        <Container size={420} my={40}>
            <Title ta="center" className={classes.title}>
                {t("core:welcome_back")}
            </Title>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <WTextInput
                    variant="default"
                    label="core:email"
                    data-test-id="email"
                    editing
                    {...form.propsForWTextInput("email")}
                />
                <PasswordInput
                    label={t("core:password")}
                    placeholder={t("core:password")}
                    data-test-id="password"
                    mt="md"
                    {...form.propsForWTextInput("password", { submitOnEnter: true })}
                />
                <Button fullWidth data-test-id="submit" mt="md" onClick={form.submit}>
                    {t("core:sign_in")}
                </Button>
                <ResetPasswordPopup />
            </Paper>
        </Container>
    )
}
Component.displayName = "SignIn"
