import React, { ForwardedRef, ReactNode, forwardRef, useContext, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { MantineColor, Menu, Tooltip, UnstyledButton, Text, Indicator } from "@mantine/core"
import { DateTime } from "@shared/dates"
import {
    useAppDispatch,
    useAppParams,
    useAppSelector,
    usePermissions,
    useAppTranslation,
    useIsMobile,
    useAppFormatter,
    useAppLanguage,
} from "hooks/hooks"
import { setFiscalYear, userSelector, allFiscalYearsSelector } from "store/appState"
import ImgWithFallback from "../custom/ImgWithFallback"
import { Role } from "@appnflat-types/Role"
import {
    IconArchive,
    IconBuildingBank,
    IconCalendar,
    IconChartBar,
    IconCoin,
    IconDoor,
    IconHeadset,
    IconHelp,
    IconHome,
    IconLayoutDashboard,
    IconMessage,
    IconSettings,
    IconTool,
    IconUserCog,
    IconUsers,
} from "@tabler/icons-react"
import * as classes from "./NavBarApp.module.css"
import { cachedBuildingSelector } from "store/cache"
import { RemoteConfigContext } from "server/RemoteConfigProvider"
import { where } from "firebase/firestore"
import { useFirestoreCount } from "hooks/useFirestoreCount"

export default function NavBarApp({
    navbarOpened,
    closeNavbar,
}: {
    navbarOpened: boolean
    closeNavbar: () => void
}) {
    const t = useAppTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const formatter = useAppFormatter()
    const location = useLocation()
    const { REQUESTS } = useContext(RemoteConfigContext)
    const { buildingRef, fiscalYear } = useAppParams()
    const isMobile = useIsMobile()
    const language = useAppLanguage()

    const userName = useAppSelector(userSelector)?.name
    const fiscalYears = useAppSelector(allFiscalYearsSelector)
    const building = useAppSelector(cachedBuildingSelector)
    const unarchivedFYs = building?.unarchivedFiscalYears
    const buildingName = building?.name

    const {
        hasSocialAccessRead,
        hasFinancialAccessRead,
        hasOwnerFinancialAccessRead,
        isAdmin,
        userRole,
    } = usePermissions()
    const numberUnreadRequestEmails = useFirestoreCount({
        coll: "requestEmails",
        additionalFilters: [where("read", "==", false)],
        permissionKey: "requests.emails",
    })

    const showSocial = !!buildingRef && hasSocialAccessRead
    const showFiscalYearPicker =
        !!buildingRef && (hasOwnerFinancialAccessRead || hasFinancialAccessRead)
    const showAllFinancial = !!buildingRef && !!fiscalYear && hasFinancialAccessRead
    const showSomeFinancial =
        !!buildingRef && !!fiscalYear && (hasOwnerFinancialAccessRead || hasFinancialAccessRead)
    const showRequests =
        REQUESTS &&
        !!buildingRef &&
        (isAdmin || userRole === Role.owner || userRole === Role.handler)
    const showAdmin = !!buildingRef && isAdmin
    const showUnits =
        !!buildingRef && !!fiscalYear && (hasFinancialAccessRead || userRole === Role.owner)

    useEffect(close, [location])

    function pathWithBuildingAndYear(to: string, noFY?: boolean, noBuilding?: boolean) {
        if (noBuilding) return `/${to}`
        if (!buildingRef) return "/"
        if (noFY) return `/building/${buildingRef}/${to}`
        const localFY = fiscalYear
            ? fiscalYear
            : unarchivedFYs
              ? Math.max(...(unarchivedFYs ?? []))
              : false
        if (!localFY) return `/building/${buildingRef}`
        return `/building/${buildingRef}/${localFY}/${to}`
    }

    function isActive(path: string, noFY?: boolean, noBuilding?: boolean) {
        const re = new RegExp(
            `^/${noBuilding ? "" : `building/${buildingRef}/`}${
                noFY ? "" : `${fiscalYear}/`
            }${path}`
        )
        return re.test(location.pathname)
    }

    function buttonProps(
        base: string,
        appendToNavigation?: string,
        noFY?: boolean,
        noBuilding?: boolean
    ) {
        return {
            onClick: () => {
                closeNavbar()
                navigate(
                    pathWithBuildingAndYear(`${base}/${appendToNavigation ?? ""}`, noFY, noBuilding)
                )
            },
            active: isActive(base, noFY, noBuilding),
            id: `navbar-${base}`,
        }
    }

    function setFiscalYearFromDropdown(year: number) {
        dispatch(setFiscalYear(year))
        if (buildingRef) navigate(`/building/${buildingRef}/${year}`)
    }

    const fiscalYearsOptions = useMemo(() => {
        return [...(fiscalYears ?? [])]
            .sort((a, b) => b - a)
            .map((year) => (
                <Menu.Item onClick={() => setFiscalYearFromDropdown(year)} key={year}>
                    {!unarchivedFYs?.includes(year) && <IconArchive />}
                    {fiscalYear === year && <b>{formatter.dateToLocale(new DateTime(+year))}</b>}
                    {fiscalYear !== year && formatter.dateToLocale(new DateTime(+year))}
                </Menu.Item>
            ))
    }, [fiscalYears, unarchivedFYs, fiscalYear, formatter])

    return (
        <div className={classes.navbar} data-expanded={navbarOpened || undefined}>
            {!isMobile && (
                <div className={classes["title-bar"]}>
                    <NavButton
                        {...buttonProps("dashboard", "", true, true)}
                        label={buildingName ?? t("core:dashboard")}
                        isAppLogo
                        customChild={
                            <ImgWithFallback
                                width="50px"
                                height="50px"
                                alt="core:appnflat_logo"
                                src="logo_small_background"
                                fetchpriority="high"
                            />
                        }
                        show
                    />
                </div>
            )}

            <NavButton
                {...buttonProps("dashboard")}
                label={buildingName ?? t("core:dashboard")}
                icon={IconLayoutDashboard}
                show={!!buildingRef}
            />
            <NavButton
                {...buttonProps("units", "transactions")}
                label={t("core:units")}
                icon={IconDoor}
                show={showUnits}
            />
            <NavButton
                {...buttonProps("people", "info")}
                label={t("core:owners_and_residents")}
                icon={IconUsers}
                show={!!buildingRef}
            />
            <NavButton
                {...buttonProps("suppliers", "transactions")}
                label={t("core:suppliers")}
                icon={IconTool}
                show={showSomeFinancial}
            />
            <NavButton
                {...buttonProps("payments", "checks")}
                label={t("core:payments")}
                icon={IconCoin}
                show={showAllFinancial}
            />
            <NavButton
                {...buttonProps("banks", "transactions")}
                label={t("core:banks")}
                icon={IconBuildingBank}
                show={showSomeFinancial}
            />
            <NavButton
                {...buttonProps("categories", "transactions")}
                label={t("core:accounting")}
                icon={IconChartBar}
                show={showSomeFinancial}
            />
            <NavButton
                {...buttonProps("posts", "all")}
                label={t("core:communications")}
                icon={IconMessage}
                show={showSocial}
            />
            <NavButton
                {...buttonProps("requests", "all")}
                label={t("core:requests")}
                icon={IconHeadset}
                show={showRequests}
                badge={numberUnreadRequestEmails}
            />

            {(showSocial || showSomeFinancial || showUnits) && <hr className="menu" />}

            <NavButton
                {...buttonProps("admin", "building")}
                label={t("core:administration")}
                icon={IconSettings}
                show={showAdmin}
            />
            <NavButton
                {...buttonProps("account", "", !fiscalYear, !buildingRef)}
                label={userName ?? t("core:account")}
                icon={IconUserCog}
                show
            />
            <NavButton
                onClick={() => window.open(`https://appnflat.com/${language}/help`, "_blank")}
                label={t("core:documentation")}
                icon={IconHelp}
                show
            />

            {showFiscalYearPicker && <hr className="menu" />}
            <Menu>
                <Menu.Target>
                    <NavButton
                        label={t("core:fiscal_year")}
                        icon={IconCalendar}
                        show={showFiscalYearPicker}
                    />
                </Menu.Target>
                <Menu.Dropdown>{fiscalYearsOptions}</Menu.Dropdown>
            </Menu>
            <NavButton
                label={t("messages:this_fiscal_year_is_archived_and_cannot_be_edited")}
                icon={IconArchive}
                color="gray"
                show={showFiscalYearPicker && !!fiscalYear && !unarchivedFYs?.includes(fiscalYear)}
            />
        </div>
    )
}

type NavButtonProps = {
    label: string
    active?: boolean
    onClick?(): void
    color?: MantineColor
    show: boolean
    /** Whether this is the app logo. */
    isAppLogo?: boolean
    id?: string
    badge?: string | number
} & (
    | {
          icon: typeof IconHome
          customChild?: undefined
      }
    | {
          icon?: undefined
          customChild: ReactNode
      }
)
const NavButton = forwardRef(function NavButtonInternal(
    {
        icon: Icon,
        customChild,
        label,
        active,
        onClick,
        color,
        show,
        isAppLogo,
        id,
        badge,
    }: NavButtonProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    if (!show) return undefined
    return (
        <Tooltip label={label} position="right" transitionProps={{ duration: 0 }} ref={ref}>
            <Indicator disabled={!badge} color="red" offset={10} label={badge} size={16}>
                <UnstyledButton
                    onClick={onClick}
                    className={classes.link}
                    data-is-logo={isAppLogo || undefined}
                    data-active={active || undefined}
                    data-no-onclick={onClick ? undefined : true}
                    id={id}
                >
                    {Icon ? (
                        <>
                            <Icon style={{ width: 22, height: 22 }} stroke={1} color={color} />
                            <Text className={classes.label}>{label}</Text>
                        </>
                    ) : (
                        customChild
                    )}
                </UnstyledButton>
            </Indicator>
        </Tooltip>
    )
})
