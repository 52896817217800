import React, { createContext, useState, useEffect, ReactNode } from "react"
import { onSnapshot, doc } from "firebase/firestore"
import { db } from "firebaseSetup"
import { sharedConfig } from "@shared/sharedConfig"

function rcValueToContext(rc: Record<string, string | boolean | number | undefined> | undefined) {
    const isDev = process.env.NODE_ENV === "development"
    return {
        /** Whether the NewReverseUnitPaymentButton is available. */
        REVERSE_PAYMENT: isDev || Boolean(rc?.REVERSE_PAYMENT),
        /** The number of transactions to show per page in the transaction table. */
        TRANSACTIONS_PER_PAGE: Number(rc?.TRANSACTIONS_PER_PAGE ?? 20),
        /** Whether the requests tab is available. */
        REQUESTS: isDev || Boolean(rc?.REQUESTS ?? true),
        /** The max length of a fiscal year, in weeks. */
        MAX_WEEKS_IN_FY: Number(rc?.MAX_WEEKS_IN_FY ?? sharedConfig.MAX_WEEKS_IN_FY),
        /** The maximum precision of a vote share.
         *
         * A value of `x` means that the vote share is rounded to `x` decimal places when written
         * as a percentage.
         *
         * @example if the vote share is 0.12345678 and `MAX_VOTE_SHARE_PRECISION` is 2, the
         * vote share will be displayed as 12.35%.
         */
        MAX_VOTE_SHARE_PRECISION: Number(
            rc?.MAX_VOTE_SHARE_PRECISION ?? sharedConfig.MAX_VOTE_SHARE_PRECISION
        ),
        /** Whether to display units, parkings, and lockers `noMonthlyFee` option. */
        NO_MONTHLY_FEE_OPTION: Boolean(rc?.NO_MONTHLY_FEE_OPTION ?? false),
    }
}

const rcDefaults = rcValueToContext(undefined)
export const RemoteConfigContext = createContext(rcDefaults)

export function RemoteConfigProvider({ children }: { children: ReactNode }) {
    const [flags, setFlags] = useState(rcDefaults)
    useEffect(() => {
        try {
            const unsubscribe = onSnapshot(
                doc(db, "settings", "remoteConfig"),
                (snap) => {
                    const remoteFlags = rcValueToContext(snap.data())
                    console.log("Remote Config flags updated to", remoteFlags)
                    setFlags(remoteFlags)
                },
                (error) => console.error("Error while updating remote config flags", error)
            )
            return unsubscribe
        } catch (error) {
            console.error("Error while updating remote config flags", error)
        }
    }, [])

    useEffect(() => {}, [])

    return <RemoteConfigContext.Provider value={flags}>{children}</RemoteConfigContext.Provider>
}
