/**************************************
 * CACHE SELECTORS
 **************************************/

import { RecursiveKeyOf, RecursiveKeysOfUnion } from "@appnflat-types/helpers"
import { TypeByCollection } from "@appnflat-types/schemas/Collection"
import { get } from "@shared/objects"
import { type RootState } from "./store"
import { IdForCollectionInput, idForCollection } from "@shared/idForCollection"
import { translate } from "logic/textDescriptions/translate"
import { WSelectOption } from "components/Inputs/WSelect"
import { Bank, BankAccountType, bankAccountTypeSchema } from "@appnflat-types/schemas/Bank"
import { Person } from "@appnflat-types/schemas/Person"
import { Unit } from "@appnflat-types/schemas/Unit"
import { Supplier } from "@appnflat-types/schemas/Supplier"
import { Transaction } from "@appnflat-types/schemas/Transaction"
import { CacheEntry, WebCacheCollections, mergeEntry } from "./cacheHelpers"
import { Category } from "@appnflat-types/schemas/Category"
import { Building } from "@appnflat-types/schemas/Building"
import { BuildingUser } from "@appnflat-types/schemas/BuildingUser"
import personName from "@shared/personName"
import { createSelectorCreator, weakMapMemoize } from "reselect"

const createSelectorWeakMap = createSelectorCreator({
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
})

type IncludeArchived = "includeArchived" | "excludeArchived"

/** Returns the list of buildings from the cache. */
export function cachedBuildingsSelector(state: RootState) {
    const keys = Object.keys(state.cache.buildings)
    const values: {
        building: Building
        /** The user's document in the building. */
        user: BuildingUser
    }[] = []
    for (let i = 0, n = keys.length; i < n; i++) {
        const key = keys[i]
        if (!key) continue
        const entry = state.cache.buildings[key]
        if (!entry) continue
        const building = mergeEntry<"buildings">(entry)
        values.push({ building, user: entry.user })
    }
    return values
}

/**
 * Returns a list of objects from the cache.
 * @param collection - The collection to return.
 * @param kind - The kind of content to show. `merged` means the cloud document with
 * local edits included. `cloud` means the document as it is currently in the cloud.
 * @param includeArchived - Whether to include or exclude archived objects.
 * @param fiscalYear - If specified, object that contain a fiscalYear field and that
 * are not from the given fiscal year will be filtered out.
 */
export function cachedCollectionSelector<C extends WebCacheCollections>(
    collection: C,
    kind: "merged" | "cloud",
    includeArchived: IncludeArchived,
    fiscalYear?: number
) {
    return function _cachedCollectionSelector(state: RootState): TypeByCollection[C][] {
        const ia = includeArchived === "includeArchived"
        const merged = kind === "merged"
        const keys = Object.keys(state.cache[collection])
        const values: TypeByCollection[C][] = []
        for (let i = 0, n = keys.length; i < n; i++) {
            const key = keys[i]
            if (!key) continue
            const entry = state.cache[collection][key]
            if (!entry) continue
            const value = merged
                ? mergeEntry<C>(entry)
                : "original" in entry
                  ? entry.original
                  : undefined
            if (
                !value ||
                (fiscalYear && "fiscalYear" in value && value.fiscalYear !== fiscalYear) ||
                (!ia && "archived" in value && value.archived)
            )
                continue
            values.push(value)
        }
        return values
    }
}

type IdForCollectionInputFilter<C extends IdForCollectionInput["collection"]> =
    IdForCollectionInput extends infer O
        ? O extends { collection: infer CO }
            ? C extends CO
                ? O
                : never
            : never
        : never

/**
 * Returns an object from the cache by identifier.
 * @param kind - The kind of content to show. `merged` means the cloud document with
 * local edits included. `cloud` means the document as it is currently in the cloud.
 */
export function cachedObjectSelector<C extends WebCacheCollections>(
    collection: C,
    idParams: IdForCollectionInputFilter<C>["value"],
    kind: "merged" | "cloud" = "merged"
) {
    return function _cachedObjectSelector(state: RootState): TypeByCollection[C] | undefined {
        // @ts-ignore
        const id = idForCollection({ collection, value: idParams })
        if (!id) return undefined
        const obj: CacheEntry<C> | undefined = state.cache[collection][id]
        if (!obj) return undefined
        return kind === "merged" ? mergeEntry<C>(obj) : "original" in obj ? obj.original : undefined
    }
}

/** Returns a field from an object from the cache by identifier. */
export function cachedObjectFieldSelector<
    C extends WebCacheCollections,
    F extends RecursiveKeysOfUnion<TypeByCollection[C]> & string,
>(collection: C, idParams: IdForCollectionInputFilter<C>["value"], field: F) {
    return function _cachedObjectFieldSelector(state: RootState) {
        // @ts-ignore
        const id = idForCollection({ collection, value: idParams })
        if (!id) return undefined
        const obj: CacheEntry<C> | undefined = state.cache[collection][id]
        if (!obj) return undefined
        const mergedObj: TypeByCollection[C] = mergeEntry<C>(obj)
        return get(mergedObj, field as any)
    }
}

/** Returns the current building. */
export function cachedBuildingSelector(state: RootState) {
    const building = Object.entries(state.cache.buildings).find(
        ([, entry]) => entry.ref === state.app.buildingRef
    )?.[1]
    if (!building) return undefined
    return mergeEntry<"buildings">(building)
}

/** Returns the user's document for the current building. */
export function cachedUserInBuildingSelector(state: RootState) {
    return Object.entries(state.cache.buildings).find(
        ([, entry]) => entry.ref === state.app.buildingRef
    )?.[1].user
}

type TransactionPartiesSelectorCollection =
    | "all"
    | "all-including-archived"
    | (
          | "units"
          | "banks"
          | "suppliers"
          | "categories"
          | `categories-${Exclude<Category["parent"], undefined>}`
          | `banks-${Exclude<Bank["bankAccountType"], undefined>}`
          | "banks-otonom"
      )[]

/**
 * Returns a list of possible transaction parties. Will not include archived accounts (except for
 * `units` which have `soldAndNeedToSetBalanceToZero` set to true, since new transactions can be
 * created for those, and for `all-including-archived`).
 * @param collections - The collections to include.
 *   - `all` means all unarchived accounts (all accounts for which a new transaction can be created),
 *   - `all-including-archived` means all accounts (useful to display transaction with potentially archived accounts),
 *   - `bank-accounts` means excluding investment and saving accounts.
 *   - `banks-otonom` means bank accounts that can be used in Otonom transactions (currently, only
 *   the bank account specified as `defaultBankAccountAID` in the building doc).
 */
export const transactionPartiesSelector = createSelectorWeakMap(
    [
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            collections === "all" ||
            collections === "all-including-archived" ||
            collections.some((c) => c.startsWith("banks"))
                ? state.cache.banks
                : undefined,
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            collections === "all" ||
            collections === "all-including-archived" ||
            collections.includes("suppliers")
                ? state.cache.suppliers
                : undefined,
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            collections === "all" ||
            collections === "all-including-archived" ||
            collections.includes("units")
                ? state.cache.units
                : undefined,
        (state: RootState, collections: TransactionPartiesSelectorCollection) =>
            collections === "all" ||
            collections === "all-including-archived" ||
            collections.some((c) => c.startsWith("categories"))
                ? state.cache.categories
                : undefined,
        (state: RootState) => {
            const buildingRef = state.app.buildingRef
            if (!buildingRef) return undefined
            return state.cache.buildings[buildingRef]
        },
        (__: RootState, collections: TransactionPartiesSelectorCollection) => collections,
        (
            __: RootState,
            __c: TransactionPartiesSelectorCollection,
            fiscalYear: number | undefined
        ) => fiscalYear,
    ],
    (banks, suppliers, units, categories, building, collections, fiscalYear) => {
        const results: WSelectOption[] = []
        const includeAllCollections =
            collections === "all" || collections === "all-including-archived"
        const includeArchived = collections === "all-including-archived"

        const banksList = banks ? Object.values(banks).map((b) => mergeEntry<"banks">(b)) : []
        const suppliersList = suppliers ? Object.values(suppliers) : []
        const unitsList = units ? Object.values(units) : []
        const categoriesList = categories
            ? Object.values(categories).map((c) => mergeEntry<"categories">(c))
            : []
        const mergedBuilding = building ? mergeEntry<"buildings">(building) : undefined
        function excludeAccount(account: Supplier | Unit | Bank | Category, isUnit?: boolean) {
            return (
                account.fiscalYear !== fiscalYear ||
                (!includeArchived &&
                    account.archived &&
                    (!isUnit ||
                        !("soldAndNeedToSetBalanceToZero" in account) ||
                        !account.soldAndNeedToSetBalanceToZero))
            )
        }
        if (includeAllCollections || collections.some((c) => c.startsWith("banks"))) {
            const types = new Set(
                Array.isArray(collections)
                    ? collections
                          .map((c) => c.startsWith("banks-") && c.split("-")[1])
                          .filter(
                              (c): c is BankAccountType =>
                                  bankAccountTypeSchema.safeParse(c).success
                          )
                    : []
            )
            const includeAllTypes = includeAllCollections || collections.includes("banks")
            const includeOtonom = includeAllCollections || collections.includes("banks-otonom")
            const otonomAID = mergedBuilding?.defaultBankAccountAID
            for (let i = 0, n = banksList.length; i < n; i++) {
                const obj = banksList[i]
                if (
                    !obj ||
                    excludeAccount(obj) ||
                    (!includeAllTypes &&
                        (!obj.bankAccountType || !types.has(obj.bankAccountType)) &&
                        (!includeOtonom || obj.aid !== otonomAID))
                )
                    continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    group: "core:banks",
                })
            }
        }
        if (includeAllCollections || collections.some((c) => c.startsWith("categories"))) {
            const types = new Set(
                Array.isArray(collections)
                    ? collections
                          .map((c) => c.startsWith("categories-") && c.split("-")[1])
                          .filter((c): c is string => !!c)
                    : []
            )
            const includeAllTypes = includeAllCollections || collections.includes("categories")
            for (let i = 0, n = categoriesList.length; i < n; i++) {
                const obj = categoriesList[i]
                if (
                    !obj ||
                    excludeAccount(obj) ||
                    (!includeAllTypes && (!obj.parent || !types.has(obj.parent)))
                )
                    continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    group: "core:accounting_categories",
                })
            }
        }
        if (includeAllCollections || collections.includes("suppliers")) {
            for (let i = 0, n = suppliersList.length; i < n; i++) {
                const entry = suppliersList[i]
                if (!entry) continue
                const obj = mergeEntry<"suppliers">(entry)
                if (excludeAccount(obj)) continue
                results.push({
                    value: obj.aid,
                    label: obj.name ? `${obj.aid} - ${obj.name}` : obj.aid,
                    group: "core:suppliers",
                })
            }
        }
        if (includeAllCollections || collections.includes("units")) {
            for (let i = 0, n = unitsList.length; i < n; i++) {
                const entry = unitsList[i]
                if (!entry) continue
                const obj = mergeEntry<"units">(entry)
                if (excludeAccount(obj, true)) continue
                results.push({
                    value: obj.aid,
                    label: obj.soldAndNeedToSetBalanceToZero
                        ? translate("unit_NUMBER_sold", { number: obj.aid })
                        : translate("unit_k", { number: obj.number ?? obj.aid }),
                    group: "core:units",
                })
            }
        }
        return results
    }
)

/**
 * Returns a preformatted list of people to be used in WSelect or WMultiSelect.
 * @param valueField - The field to use for the value of a select option. If left empty,
 * the field used will be `uuid`.
 */
export const peopleOptionsSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.people,
        (__: RootState, includeArchived: IncludeArchived) => includeArchived,
        (__: RootState, __i: IncludeArchived, fiscalYear: number | undefined) => fiscalYear,
        (
            __: RootState,
            __i: IncludeArchived,
            __f: number | undefined,
            valueField: RecursiveKeyOf<Person> = "uuid"
        ) => valueField,
    ],
    (people, includeArchived, fiscalYear, valueField) => {
        return Object.entries(people).flatMap(([, entry]) => {
            const obj = mergeEntry<"people">(entry)
            if (
                obj.fiscalYear !== fiscalYear ||
                (includeArchived === "excludeArchived" && obj.archived)
            )
                return []
            return [
                {
                    value: get(obj, valueField) as string,
                    label: personName(obj),
                },
            ]
        })
    }
)

/**
 * Returns a preformatted list of units to be used in WSelect or WMultiSelect.
 * @param valueField - The field to use for the value of a select option. If left empty,
 * the field used will be `uuid`.
 */
export const unitsOptionsSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.units,
        (__: RootState, includeArchived: IncludeArchived) => includeArchived,
        (__: RootState, __i: IncludeArchived, fiscalYear: number | undefined) => fiscalYear,
        (
            __: RootState,
            __i: IncludeArchived,
            __f: number | undefined,
            valueField: RecursiveKeyOf<Unit> = "uuid"
        ) => valueField,
    ],
    (units, includeArchived, fiscalYear, valueField) => {
        return Object.entries(units).flatMap(([, entry]) => {
            const obj = mergeEntry<"units">(entry)
            if (
                obj.fiscalYear !== fiscalYear ||
                (includeArchived === "excludeArchived" && obj.archived)
            )
                return []
            return [
                {
                    value: get(obj, valueField) as string,
                    label: obj.soldAndNeedToSetBalanceToZero
                        ? translate("unit_NUMBER_sold", { number: obj.aid })
                        : translate("unit_k", { number: obj.number ?? obj.aid }),
                },
            ]
        })
    }
)

/**
 * Returns a preformatted list of suppliers to be used in WSelect or WMultiSelect.
 * @param valueField - The field to use for the value of a select option. If left empty,
 * the field used will be `uuid`.
 */
export const suppliersOptionsSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.suppliers,
        (__: RootState, includeArchived: IncludeArchived) => includeArchived,
        (__: RootState, __i: IncludeArchived, fiscalYear: number | undefined) => fiscalYear,
        (
            __: RootState,
            __i: IncludeArchived,
            __f: number | undefined,
            valueField: RecursiveKeyOf<Supplier> = "uuid"
        ) => valueField,
    ],
    (suppliers, includeArchived, fiscalYear, valueField) => {
        return Object.entries(suppliers).flatMap(([, entry]) => {
            const obj = mergeEntry<"suppliers">(entry)
            if (
                obj.fiscalYear !== fiscalYear ||
                (includeArchived === "excludeArchived" && obj.archived)
            )
                return []
            return [
                {
                    value: get(obj, valueField) as string,
                    label: obj.name ?? obj.aid,
                },
            ]
        })
    }
)

/**
 * Returns a preformatted list of units to be used in WSelect or WMultiSelect.
 * @param valueField - The field to use for the value of a select option. If left empty,
 * the field used will be `uuid`.
 */
export const transactionsSelector = createSelectorWeakMap(
    [
        (state: RootState) => state.cache.transactions,
        (state: RootState) => state.cache.unreconciledTransactions,
        (__: RootState, fiscalYear: number | undefined) => fiscalYear,
    ],
    (transactions, unreconciledTransactions, fiscalYear) => {
        const keysTransactions = Object.keys(transactions)
        const keysUnreconciledTransactions = Object.keys(unreconciledTransactions)
        const values: {
            transaction: Transaction
            collection: "transactions" | "unreconciledTransactions"
        }[] = []
        for (let i = 0, n = keysTransactions.length; i < n; i++) {
            const key = keysTransactions[i]
            if (!key) continue
            const entry = transactions[key]
            if (!entry) continue
            const transaction = mergeEntry<"transactions">(entry)
            if (!transaction || transaction.fiscalYear !== fiscalYear) continue
            values.push({ transaction, collection: "transactions" as const })
        }
        for (let i = 0, n = keysUnreconciledTransactions.length; i < n; i++) {
            const key = keysUnreconciledTransactions[i]
            if (!key) continue
            const entry = unreconciledTransactions[key]
            if (!entry) continue
            const transaction = mergeEntry<"unreconciledTransactions">(entry)
            if (!transaction || transaction.fiscalYear !== fiscalYear) continue
            values.push({ transaction, collection: "unreconciledTransactions" as const })
        }
        return values
    }
)
