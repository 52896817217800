import { z } from "zod"
import { AttachmentUploadedSchema, dineroStorableSchema, objectWithUUIDSchema } from "./Common"
import { PAYMENT_METHODS } from "../../@constants/PaymentMethod"
import { ParsingErrors } from "./parsingErrors"
import {
    AidString,
    aidString,
    mediumString,
    shortString,
    uidString,
    uuidString,
    veryShortString,
} from "./BaseStrings"
import { zodRegex } from "./zodExtensions"

export const subTransactionSchema = z.object({
    amount: dineroStorableSchema,
    aid: aidString,
    check: veryShortString.optional(),
})
export type SubTransaction = z.infer<typeof subTransactionSchema>

export const transactionApprovalStatusSchema = z.enum(["approved", "rejected", "pending"])
export type TransactionApprovalStatus = z.infer<typeof transactionApprovalStatusSchema>

export const transactionPaymentStatusSchema = z.enum([
    "paid",
    "error",
    "cancelled",
    "waitingApproval",
    "readyToSendToOtonom",
])
export type TransactionPaymentStatus = z.infer<typeof transactionPaymentStatusSchema>

/** The status of a transaction. */
export const transactionStatusSchema = z.enum(["normal", "cancelled", "pending"])
/** The status of a transaction. */
export type TransactionStatus = z.infer<typeof transactionStatusSchema>

/** All the different kinds of transactions we have. */
export const transactionKindSchema = z.enum([
    /** Transferring the balance from a unit to the new version of the unit when selling it. */
    "unitBalanceTransfer",
    /** The fee applied to a unit every month. */
    "unitMonthlyFee",
    /** A transaction where a unit pays the building. */
    "unitPayment",
    /** A transaction to reverse the unit's payment. */
    "unitPaymentReversal",
    /** The automatically applied late fee. */
    "unitLateFee",
    /** The automatically applied interest fee. */
    "unitInterestFee",
    /** A penalty for reversed payments. */
    "unitPaymentReversalFee",
    /** A custom penalty for a unit, for example for parking in the wrong spot.  */
    "unitCustomPenalty",
    /** A custom fee for a unit, for example to purchase an additional key fob.  */
    "unitCustomFee",
    /** A credit note from the building to a unit. */
    "unitCreditNote",
    /** A budget correction for the monthly fees paid by a unit. */
    "budgetCorrection",
    /** The invoice of a recurring bill. */
    "recurringBill",
    /** The payment of a recurring bill. */
    "recurringBillPayment",
    /** A transaction that is a supplier invoicing the building. */
    "supplierInvoice",
    /** A transaction that is the building paying the supplier. */
    "supplierPayment",
    /** A transaction that is the supplier giving the building a credit. */
    "supplierCreditNote",
    /** A transaction that can be anything, including a nn transaction. */
    "accountantWriting",
    /** Interests received on a bank account from a bank (for example on a savings account). */
    "bankInterestReceived",
    /** Interests paid to a bank (for example on a credit card). */
    "bankInterestPaid",
    "bankCapitalPaid",
    /** A transfer between two bank accounts. */
    "bankTransfer",
    /** A fee from a bank (which goes from a bank account to a bank supplier account). */
    "bankFee",
    /** A transaction where a category is the only creditor or debtor. */
    "categorySimple",
])
export type TransactionKind = z.infer<typeof transactionKindSchema>

/**
 * The possible values of a transaction index entry.
 * - `isDeposit:${bankAID}`: a cash or check deposit in a bank account
 * - `isElectronicDeposit:${bankAID}`: a wire-transfer deposit in a bank account
 * - `isOutgoingCheck:${bankAID}`: a check going out of a bank account (i.e., used by the building to pay someone)
 * - `aid:${aid}`: involves the specified aid
 * - `aid:${aid}inv:${invoiceNumber}`: an invoice involving the specified aid
 * - `isUnpaidInv:${supplierAID}`: an unpaid invoice from a supplier
 */
export const transactionIndexSchema = z.union([
    zodRegex<`aid:${string}`>(/^aid:(\d+(\.\d+|))$/),
    zodRegex<`isDeposit:${string}`>(/^isDeposit:(\d+(\.\d+|))$/),
    zodRegex<`isElectronicDeposit:${string}`>(/^isElectronicDeposit:(\d+(\.\d+|))$/),
    zodRegex<`isOutgoingCheck:${string}`>(/^isOutgoingCheck:(\d+(\.\d+|))$/),
    zodRegex<`isUnpaidInv:${string}`>(/^isUnpaidInv:(\d+(\.\d+|))$/),
    zodRegex<`aid:${string}inv:${string}`>(/^aid:(\d+(\.\d+|))inv:[^\s#/]+$/),
])
/** The possible values of a transaction index entry.
 * - `isDeposit:${bankAID}`: a cash or check deposit in a bank account
 * - `isElectronicDeposit:${bankAID}`: a wire-transfer deposit in a bank account
 * - `isOutgoingCheck:${bankAID}`: a check going out of a bank account (i.e., used by the building to pay someone)
 * - `aid:${aid}`: involves the specified aid
 * - `aid:${aid}inv:${invoiceNumber}`: an invoice involving the specified aid
 * - `isInv:${supplierAID}`: an invoice from a supplier
 */
export type TransactionIndex = z.infer<typeof transactionIndexSchema>

/** The core object of a transaction.
 *
 * WARNING: This schema is not complete and should almost NEVER be used directly. Use `transactionSchema` instead.
 */
export const transactionCoreSchema = objectWithUUIDSchema.extend({
    date: z.number(),
    archived: z.boolean().optional(),
    fiscalYear: z.number(),
    subtransactions: z
        .array(subTransactionSchema)
        .refine((subs) => subs.some((sub) => sub.amount.amount !== 0), {
            message: ParsingErrors.some_subtransaction_should_have_non_null_amount,
        })
        .refine(
            (subs) => Math.abs(subs.reduce((acc, sub) => acc + sub.amount.amount, 0)) < 0.0001,
            { message: ParsingErrors.subtransactions_should_balance_out_to_zero }
        ),
    description: mediumString.optional(),
    indexes: z.array(transactionIndexSchema),
    paymentMethod: z.enum(PAYMENT_METHODS).optional(),
    deposit: veryShortString.optional(),
    check: veryShortString.optional(),
    /** If the transaction has an associated document, the file format it is in. */
    attachmentUploaded: AttachmentUploadedSchema.optional(),
    /** Whether the transaction was imported. */
    imported: z.boolean().optional(),
    /** The status of the transaction.
     * - `"normal"` or `undefined`: the transaction is normal
     * - `"cancelled"`: the transaction has been cancelled and should not be considered in the
     *   accounting
     * - `"pending"`: the transaction is pending approval and should not be considered in the
     *   accounting
     */
    status: transactionStatusSchema.optional(),
    /** Fields related to a cancelled transaction. Should only be set if `status === "cancelled"`. */
    cancelled: z
        .object({
            /** The reason why the transaction was cancelled. */
            reason: mediumString.optional(),
            /** The uid of the admin who cancelled the transaction. */
            by: uidString.optional(),
        })
        .optional(),
})

const reconciledEntrySchema = zodRegex<`${AidString}:${number | "" | "pFY"}`>(
    /^(\d+\.\d+|\d+):(\d+|pFY|)$/
)
const reconciledSchema = z.object({
    /** A list of reconciled bank accounts and the unix epoch at which they were reconciled.
     * Should contain all bank accounts that the transaction is associated with.
     * If the transaction is not reconciled for a bank account, the unix epoch should be empty.
     * If the transaction is an `unreconciledTransaction` and a bank account was reconciled in the
     * previous fiscal year, the unix epoch should be `pFY` (`previousFiscalYear`).
     *
     * @example
     * ["150:1111", "151:", "152:pFY"] => The transaction was reconciled for the bank account 150 at
     * unix epoch 1111, is not reconciled for the bank account 151, and was reconciled for the bank
     * account 152 in the previous fiscal year.
     */
    reconciled: z.array(reconciledEntrySchema).optional(),
})
/** The type of a single entry in a transaction's `reconciled` field. */
export type TransactionReconciledEntry = z.infer<typeof reconciledEntrySchema>

/**
 * The schema for the invoice number.
 *
 * This is used for a part of a document id. As such, it cannot contain spaces, slashes or hashtags
 * and should be short.
 */
export const invoiceNumberSchema = z
    .string()
    .min(1)
    .max(300)
    .regex(/^[^\s/#]*$/, { message: ParsingErrors.cannot_contain_space_slash_or_hashtag })

export const paidInvoiceSchema = z.object({
    /** The uuid of the transaction that created the original invoice. */
    transactionUUID: uuidString, //.optional(),
    /** The fiscalYear of the transaction that created the original invoice. */
    transactionFiscalYear: z.number(), //.optional(),
    invoiceNumber: invoiceNumberSchema,
    amount: dineroStorableSchema,
    /** The `aid` of the supplier. */
    supplier: aidString,
})
export type PaidInvoice = z.infer<typeof paidInvoiceSchema>

const paidInvoicesSchema = z
    .array(paidInvoiceSchema)
    // Ensure invoices are present only once in the list.
    .refine(
        (invoices) =>
            invoices.length ===
            new Set(invoices.map((i) => `${i.supplier}#${i.invoiceNumber}`)).size
    )

/** The schema for transaction that have Otonom payment details. */
const transactionOtonomDetailsSchema = transactionCoreSchema.extend({
    /** The payment status of the transaction. */
    paymentStatus: transactionPaymentStatusSchema.optional(),
    otonomBatch: uuidString.optional(),
})
export type TransactionOtonomDetails = z.infer<typeof transactionOtonomDetailsSchema>

/******************************************
 * FINAL TRANSACTION SCHEMAS
 ******************************************/

const transactionKindWithBank = [
    "bankInterestReceived",
    "bankInterestPaid",
    "bankCapitalPaid",
    "bankTransfer",
    "bankFee",
    "accountantWriting",
    "unitPaymentReversal",
] as const

/** Transaction kinds that can have a `reconciled` field. */
export const transactionKindWithReconciled = [
    ...transactionKindWithBank,
    "unitPayment",
    "supplierPayment",
    "recurringBillPayment",
] as const

/** The schema for most transactions. */
const transactionDefaultSchema = transactionCoreSchema.extend({
    kind: transactionKindSchema.exclude([
        "supplierInvoice",
        "supplierCreditNote",
        ...transactionKindWithReconciled,
    ]),
})

const transactionWithBankSchema = transactionCoreSchema
    .extend({
        kind: transactionKindSchema.extract(transactionKindWithBank),
    })
    .merge(reconciledSchema)

/** The schema for transaction that are `unitPayment`s or `recurringBillPayment`s. */
const transactionOtonomWithoutApprovalSchema = transactionOtonomDetailsSchema
    .extend({
        kind: transactionKindSchema.extract(["unitPayment", "recurringBillPayment"]),
    })
    .merge(reconciledSchema)

const transactionInvoiceSchema = z.object({
    /** The invoice number. Spaces, '/', and '#' are forbidden characters. Up to 300 characters max. */
    number: invoiceNumberSchema,
    /** The uuids of transactions that are payments of this invoice. */
    paymentTransactions: z.array(z.string()).optional(),
    /** The amount of the invoice that has not yet been paid. Does not include `pendingAmount`. */
    unpaidAmount: dineroStorableSchema,
    /** The amount of the invoice for which there are pending payments. Not included in `unpaidAmount`. */
    pendingAmount: dineroStorableSchema,
})
export type TransactionInvoice = z.infer<typeof transactionInvoiceSchema>

/** The schema for transaction that are `supplierInvoice`s. */
const transactionSupplierInvoicePartSchema = transactionCoreSchema.extend({
    invoice: transactionInvoiceSchema,
    kind: transactionKindSchema.extract(["supplierInvoice"]),
})

/** The schema for transaction that are `supplierCreditNote`s. */
const transactionSupplierCreditNotePartSchema = transactionCoreSchema.extend({
    /** The invoices that were marked as paid in this transaction. */
    paidInvoices: paidInvoicesSchema, //.optional(),
    kind: transactionKindSchema.extract(["supplierCreditNote"]),
})

/** The schema for transaction that are `supplierPayment`s. */
const transactionSupplierPaymentPartSchema = transactionOtonomDetailsSchema
    .extend({
        /** The invoices that were marked as paid in this transaction. */
        paidInvoices: paidInvoicesSchema, //.optional(),
        /** Whether this transaction has been requested for approval for the disbursement of funds. */
        requestedApproval: z.boolean().optional(),
        /** The list of uid of admins who have approved this transaction. */
        approvedBy: z.array(shortString).optional(),
        /** The list of uid of admins who have rejected this transaction. */
        rejectedBy: z.array(shortString).optional(),
        /** The current status of the transaction.
         * - `approved`: approved by all the admins with approval permission at the time
         * - `rejected`: rejected by any admin
         * - `pending`: any other state
         */
        approvalStatus: transactionApprovalStatusSchema.optional(),
        kind: transactionKindSchema.extract(["supplierPayment"]),
    })
    .merge(reconciledSchema)

/** All possible schemas of transactions. */
export const transactionSchema = z.discriminatedUnion("kind", [
    transactionDefaultSchema,
    transactionWithBankSchema,
    transactionOtonomWithoutApprovalSchema,
    transactionSupplierInvoicePartSchema,
    transactionSupplierCreditNotePartSchema,
    transactionSupplierPaymentPartSchema,
])
export type Transaction = z.infer<typeof transactionSchema>
