import { Currency, Dinero } from "dinero.js"
import { Account } from "../@appnflat-types/schemas/Common"
import { sumAmountsByDate } from "./sumAmountsByDate"
import { absoluteValue, safeDineroFactory, toDinero } from "./dineroExtensions"

/**
 * Calculates the balance of an account.
 *
 * @param kind The kind of object for which we are calculating the balance, which
 *  defined which formula is applied.
 *  In the following, ≈ means "equivalent to". As such, "debits (≈invoiced)" means debits,
 *  which are equivalent to the amounts invoiced.
 *      - `unit`:
 *          balance = due to syndicate
 *          = starting balance + debits (≈invoiced by syndicate) - credits (≈paid by unit)
 *      - `supplier`:
 *          balance = due to supplier
 *          = starting balance + credits (≈invoiced by supplier) - debits (≈paid by syndicate)
 *      - `bankOperatingAccount`: Show the bank balance as the inverse of how it would appear
 *        on a bank statement, which is how it is recorded in our system.
 *          balance = starting balance + credits - debits
 *      - `bankStatement`: Show the bank balance as it would appear on a bank statement.
 *          balance = -1 * (starting balance + credits - debits)
 *      - `categoryExpense`: balance = starting balance + credit - debit
 *      - `categoryRevenue`: balance = starting balance + debit - credit
 *      - `SB+D-C`: balance = starting balance + debits - credits
 *      - `SB+C-D`: balance = starting balance + credits - debits
 *      - `D-C`: balance = debits - credits
 *      - `C-D`: balance = credits - debits
 */
export function calculateBalance(
    obj: Pick<Account, "startingBalance" | "credits" | "debits">,
    currency: Currency | undefined,
    kind:
        | "unit"
        | "supplier"
        | "bankOperatingAccount"
        | "bankStatement"
        | "categoryExpense"
        | "categoryRevenue"
        | "SB+D-C"
        | "SB+C-D"
        | "D-C"
        | "C-D"
): Dinero {
    const sb = safeDineroFactory(obj.startingBalance, currency)
    const debits = sumAmountsByDate(obj.debits, currency)
    const credits = sumAmountsByDate(obj.credits, currency)

    let balance = toDinero(0, currency)

    if (kind === "D-C") {
        balance = debits.subtract(credits)
    } else if (kind === "C-D") {
        balance = credits.subtract(debits)
    } else if (kind === "unit" || kind === "categoryRevenue" || kind === "SB+D-C") {
        balance = sb.add(debits).subtract(credits)
    } else {
        balance = sb.add(credits).subtract(debits)
    }

    if (kind === "bankStatement") balance = balance.multiply(-1)

    if (balance.isZero()) return absoluteValue(balance)
    return balance
}
